import React, {
  createContext,
  useReducer,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import About from "./components/Content/About";
import Leaders from "./components/Content/Leaders";
import First from "./components/Assets/First";
import Media from "./components/Pages/Media/Media";
import Esg from "./components/Pages/ESG/Esg";
import Logout from "./components/authenticate/Logout";
import Faqs from "./components/Footer/Faqs";
import Home from "./components/Content/Home";
import ErrorPage from "./components/Table/ErrorPage";
import RingLoader from "./components/Layout/RingLoader";
import { redirect_uri, app_url, client_id, clientSecret, tokenUrl, fetch_bucket } from "./config/environment";
import "./App.css";

import {
  initialState,
  reducer,
  initialStatus,
  accessReducer,
  nameReducer,
  initialName,
} from "./reducer/UseReducer";

import Terms from "./components/Pages/Terms/Terms";
import BrandFolders from "./components/Table/CustomRestFolder";
import IntBrand from "./components/Pages/IntBrand/IntBrand";
import IntCountries from "./components/Pages/IntCountries/IntCountries";
import Business from "./components/Pages/IntBusiness/Business";
import NewsFeedLayout from "./components/Assets/NewsFeedLayout";
import PrivateBrandCatalog from "./components/Assets/PrivateBrandCatalog/PrivateBrandCatalog";
import ScrollToTop from "./components/Layout/ScrollToTop";
import PageUnderCons from "./components/Pages/PageUnderCons/PageUnderCons";
import SubCategoryLayout from "./components/Assets/PrivateBrandCatalog/SubCategoryLayout";
import ProductDetailLayout from "./components/Assets/PrivateBrandCatalog/ProductDetailLayout";
import AllNotification from "./components/Assets/PrivateBrandCatalog/AllNotification";
import ContactUsPage from "./components/Footer/ContactUsPage";
import Careers from "./components/Content/Careers";
import AdsLandingPage from "./components/Content/AdsLandingPage";
import { resValidation } from "./Utils/CommonUtils";
import PSALayout from "./components/Assets/PrivateBrandCatalog/PSALayout";
import SearchLayout from "./components/Assets/PrivateBrandCatalog/SearchLayout";
import MyListLayout from "./components/Assets/PrivateBrandCatalog/MyListLayout";
import axios from "axios";
import { SnackbarProvider, useSnackbar } from "notistack";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { addItemToList } from "./components/Assets/PrivateBrandCatalog/PBCatalogSlice";
import { useSelector, useDispatch } from "react-redux";
import UserManagement from "./components/Assets/UserManagement/UserManagement";
import AddUser from "./components/Assets/UserManagement/AddUser";
import BrandedMerchandise from "./components/Assets/BrandedMerchandise/BrandedMerchandise"
import ManageCollections from './components/Assets/BrandedMerchandise/BMManagement/ManageCollection';
import CollectionProductList from './components/Assets/BrandedMerchandise/CollectionProductList';
import ProductDetailsPage from "./components/Assets/BrandedMerchandise/ProductDetailsPage";
import ProtectedRoute from "./Utils/ProtectedRoute";
import { checkExpiryAndRedirect } from "./Utils/CommonUtils";
import CreateCollection from "./components/Assets/BrandedMerchandise/BMManagement/CreateCollection";
import BMListLayout from "./components/Assets/BrandedMerchandise/BMListLayout";
import AddProduct from "./components/Assets/BrandedMerchandise/BMManagement/AddProduct";
var qs = require("qs");

export const UserContext = createContext();
export const StatusContext = createContext();
export const NameContext = createContext();
var code="";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
  // const client_id = process.env.REACT_APP_SICI;
  // const clientSecret = process.env.REACT_APP_SICS;
  // const tokenUrl = process.env.REACT_APP_TOKEN_URL;
  // const app_url = process.env.REACT_APP_URI;
  // const fetch_bucket = process.env.REACT_APP_FETCH_BUCKET;
  const checkAccessToken = localStorage.getItem("access_token");

  const [state, dispatch] = useReducer(reducer, initialState);
  const [status, dispatchStatus] = useReducer(accessReducer, initialStatus);
  const [name, dispatchName] = useReducer(nameReducer, initialName);

  const isMounted = useRef(false);

  axios.interceptors.request.use((config) => {
    const exemptCheck = ['fetchCareers','getEsgReports','submitContact']
    const urlComponents = config.url.split('/')
    if([exemptCheck.includes(urlComponents[urlComponents.length - 1])] || localStorage.getItem("access_token")){
      return config;
    }

  })

  // const [tokenDetails, setTokenDetails] = useState("");
  const [paths, setPaths] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState("");
  const [reloadPath, setReloadPath] = useState("/assets/");
  const [gettingBucket, setGettingBucket] = useState(true);
  const [userDetailUpdate, setUserDetailUpdate] = useState(false);
  const [bucketError, setBucketError] = useState(true);
  const [callFetchBucket, setCallFetchBucket] = useState(false);
  const { closeSnackbar } = useSnackbar();

  const notistackRef = useRef();
  const reduxDispatch = useDispatch();
  const {myList= []} = useSelector(state=> state.pbCatalog);
  const { isAdmin=false } = useSelector(state => state.userManagement);
  
  const action = (key) => (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={() => {
        notistackRef.current.closeSnackbar(key);
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  const expTime = localStorage.getItem("expiry_time");
  const pageAccessedByReload =
    (window.performance.navigation &&
      window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType("navigation")
      .map((nav) => nav.type)
      .includes("reload");

  const userName = localStorage.getItem("username");

  useEffect(() => {
    code = location.search;
    code = code.substring(6);
  }, []);

  useEffect(() => {
    const accTok = localStorage.getItem("access_token");
    if (
      isMounted.current &&
      /* !tokenDetails && */
      !accTok &&
      !pageAccessedByReload & (expTime < Date.now() / 1000)
    ) {
      fetchAccessToken();
    } else {
      isMounted.current = true;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accTok}`;
    }
  }, [code]);
  useEffect(() => {
    let myListFromLocale = localStorage.getItem("pb_my_list");
      if (myListFromLocale) {
        let storedList = JSON.parse(myListFromLocale);
        if(storedList.length != myList.length){
          reduxDispatch(addItemToList(storedList));
        }
      }
  }, []);
  /* const accTok = localStorage.getItem("access_token");
  useEffect(() => {
    console.log("use effect log:",accTok)
    if(accTok){
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accTok}`;
    } 
    return ()=>{
      console.log("return use effect:",accTok)
    };
  },[accTok]); */

  if (!pageAccessedByReload & (expTime < Date.now() / 1000)) {
    localStorage.setItem("bucket_data", JSON.stringify(paths));
    localStorage.setItem("bucket_paths", JSON.stringify(routes));
  }
  const accTok = localStorage.getItem("access_token");
  if(accTok){
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accTok}`;
  } 

  const fetchAccessToken = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${tokenUrl}/oauth2/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          grant_type: "authorization_code",
          client_id: client_id,
          redirect_uri: `https://${redirect_uri}/assets`,
          code: code,
          client_secret: clientSecret,
        }),
      });

      const data = await res.json();
      if (res.status === 200) {
        dispatchStatus({ type: "success", payload: true });
        const tokenD = data;
        const accTok = tokenD.access_token || '';
        const idTok = tokenD.id_token || '';
        const refTok = tokenD.refresh_token || '';
        localStorage.setItem("id_token", idTok);
        localStorage.setItem("access_token", accTok);
        localStorage.setItem("refresh_token", refTok);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("access_token")}`;
        // if (!tokenDetails) setTokenDetails(tokenD);
      } else {
        if (!status) {
          dispatchStatus({ type: "success", payload: false });
        }
      }

      if (!(res.status === 200)) {
        const error = new Error(res.error);
        return res.send({ message: error });
      }
      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    state && getBucketData();
  }, [callFetchBucket, checkAccessToken]);

  const toggleCallFetchBucket = () => {
    setCallFetchBucket(!callFetchBucket);
  };

  const getBucketData = async () => {
    setGettingBucket(true);
    try {
      var data = qs.stringify({
        accessToken: checkAccessToken,
      });
      var config = {
        method: "post",
        url: fetch_bucket,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          let validatedRes = resValidation(response);
          let data = validatedRes.data;
          if (data) {
            setPaths(data.body.message.folderStructure);
            setRoutes(data.body.message.folderPaths);
            localStorage.setItem(
              "bucket_data",
              JSON.stringify(data.body.message.folderStructure)
            );
            localStorage.setItem(
              "bucket_paths",
              JSON.stringify(data.body.message.folderPaths)
            );
          }
          setBucketError(false);
          setGettingBucket(false);
          setIsLoading(false);
          let code = location.pathname;
          if (
            code.includes("/Digital-assets") ||
            code.includes("/digital-assets") ||
            code.includes("/brand-Standards") ||
            code.includes("/Brand-Standards") ||
            code.includes("/social%20media%20assets") ||
            code.includes("/Social%20Media%20Assets") ||
            code.includes("/workshop-materials") ||
            code.includes("/Workshop-Materials")
          ) {
            navigate(reloadPath);
          }
        })
        .catch(function (error) {
          setBucketError(true);
          setIsLoading(false);
          setGettingBucket(false);
        });
    } catch (err) {
      setBucketError(true);
      setIsLoading(false);
      setGettingBucket(false);
    }
  };

  if (state) {
    const authTime = localStorage.getItem("auth_time");
    const expTime = localStorage.getItem("expiry_time");

    checkExpiryAndRedirect();
  }

  let renderRoutes = null;
  let path = "";

  if (!isLoading && state) {
    path = JSON.parse(localStorage.getItem("bucket_data"));

    const foldRoute = JSON.parse(localStorage.getItem("bucket_paths"));

    let pathRoutes = [];

    if (foldRoute) {
      foldRoute.map((folder) => {
        let ans = [];
        let lastidx = 0;
        for (let i = 0; i < folder.length; i++) {
          if (folder[i] === "/") {
            let temp = [];
            if (lastidx === 0) {
              lastidx = i;
              temp.push(folder.slice(0, i));
              temp.push(`/${folder.slice(0, i)}`);
            } else {
              temp.push(folder.slice(lastidx + 1, i));
              temp.push(`/${folder.slice(0, i)}`);
              lastidx = i;
            }
            ans.push(temp);
          } else if (i === folder.length - 1 && lastidx === 0) {
            let temp = [];
            temp.push(folder.slice(lastidx, folder.length));
            temp.push(`/${folder.slice(0, folder.length)}`);
            ans.push(temp);
          } else if (i === folder.length - 1 && lastidx !== 0) {
            let temp = [];
            temp.push(folder.slice(lastidx + 1, folder.length));
            temp.push(`/${folder.slice(0, folder.length)}`);
            ans.push(temp);
          }
        }

        let passPaths;
        let ansLen = ans.length;
        passPaths = path.rootFolders[`${ans[0][0]}`];
        localStorage.setItem("setpath1", path.rootFolders[`${ans[0][0]}`]);

        for (let i = 1; i < ansLen; i++) {
          passPaths = passPaths[`${ans[i][0]}`];
        }

        let pathRoute = {
          pathName: `/${folder}`,
          routePath: passPaths,
          directName: [["Assets", "/assets/"], ...ans],
          url: `/${folder}`,
          heading: ans[0][0],
        };

        pathRoutes.push(pathRoute);
      });
    }
    localStorage.setItem("checkpaths", JSON.stringify(pathRoutes));

    renderRoutes = pathRoutes.map(
      ({ pathName, routePath, directName, url, heading }) => (
        <Route
          exact
          path={pathName}
          element={
            state ? (
              !["7in_post","7in_user"].includes(heading)?
              <ProtectedRoute
              element={
                <BrandFolders
                  paths={path ? routePath : null}
                  directoryNames={directName}
                  url={url}
                  heading={heading}
                  setCallFetchBucket={toggleCallFetchBucket}
                  setReloadPath={setReloadPath}
                  setIsLoading={setIsLoading}
                  reloadPath={reloadPath}
                />
              }
              feature={heading.replace("-", " ")}
              access="view"
              allowAdmin = {isAdmin}
              /> :
              <BrandFolders
                paths={path ? routePath : null}
                directoryNames={directName}
                url={url}
                heading={heading}
                setCallFetchBucket={toggleCallFetchBucket}
                setReloadPath={setReloadPath}
                setIsLoading={setIsLoading}
                reloadPath={reloadPath}
              />
            ) : (
              <ErrorPage />
            )
          }
        />
      )
    );
  }

  const handleUserDetailUpdate = () => {
    setUserDetailUpdate(!userDetailUpdate);
  };

  return (
    <div>
      <ScrollToTop />
      <StatusContext.Provider value={{ status, dispatchStatus }}>
        <NameContext.Provider value={{ name, dispatchName }}>
          <UserContext.Provider value={{ state, dispatch }}>
            <SnackbarProvider
              autoHideDuration={3000}
              ref={notistackRef}
              action={action}
            >
            <Layout handleUserDetailUpdate={handleUserDetailUpdate}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />\
                <Route exact path="/intbrand" element={<IntBrand />} />
                <Route exact path="/int-countries" element={<IntCountries />} />
                <Route exact path="/business-model" element={<Business />} />
                <Route exact path="/leadership" element={<Leaders />} />
                <Route exact path="/loader" element={<RingLoader />} />
                <Route
                  path="/assets"
                  element={
                    isLoading ? (
                      <RingLoader />
                    ) : (
                      <NewsFeedLayout
                        setCallFetchBucket={toggleCallFetchBucket}
                        bucketError={bucketError}
                        gettingBucket={gettingBucket}
                        setGettingBucket={setGettingBucket}
                        refresh={pageAccessedByReload}
                        userDetailUpdate={userDetailUpdate}
                        handleUserDetailUpdate={handleUserDetailUpdate}
                      />
                    )
                  }
                />
                <Route exact path="/first" element={<First />} />
                {renderRoutes}
                <Route exact path="/media" element={<Media />} />
                <Route exact path="/esg" element={<Esg />} />
                <Route exact path="/logout" element={<Logout />} />
                <Route exact path="/faqs" element={<Faqs />} />
                <Route exact path="/logout" element={<Logout />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route
                  path="Digital-assets/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Digital assets" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route
                  path="Brand-Standards/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Brand Standards" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route
                  path="Social Media Assets/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Social Media Assets" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route
                  path="Social%20Media%20Assets/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Social Media Assets" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route
                  path="Workshop-Materials/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Workshop Materials" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route
                  path="Newsletter/*"
                  element={
                    isLoading ? <RingLoader/> :
                    <ProtectedRoute 
                    element={<Navigate to={path} replace={false} />} 
                    feature="Newsletter" 
                    access="view" 
                    allowAdmin = {isAdmin}/>
                  }
                />
                <Route exact path="/contact-us" element={<ContactUsPage />} />
                <Route exact path="/careers" element={<Careers />} />
                <Route
                  exact
                  path="/ads-landing-page"
                  element={<AdsLandingPage />}
                />
                <Route
                  path="/privateBrandCatalog"
                  element={
                    <ProtectedRoute 
                      element={<PrivateBrandCatalog />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  } 
                />
                <Route
                  path="/privateBrandCatalog/:countryName"
                  element={
                    <ProtectedRoute 
                      element={<PSALayout />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/privateBrandCatalog/:countryName/category/:psaName"
                  element={
                    <ProtectedRoute 
                      element={<SubCategoryLayout />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/privateBrandCatalog/:countryName/search"
                  element={
                    <ProtectedRoute 
                      element={<SearchLayout />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/privateBrandCatalog/my-list"
                  element={
                    <ProtectedRoute 
                      element={<MyListLayout />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/privateBrandCatalog/:countryName/:psaName/:subCat/:itemID"
                  element={
                    <ProtectedRoute 
                      element={<ProductDetailLayout />}
                      feature="Private Brand Catalog" 
                      access="view"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/userManagement"
                  element={
                    <ProtectedRoute 
                      element={<UserManagement />}
                      feature="User Management" 
                      access="edit"
                      allowAdmin = {isAdmin}
                    />
                  }
                /> 
                <Route
                  path="/userManagement/add-edit-user"
                  element={
                    <ProtectedRoute 
                      element={<AddUser />}
                      feature="User Management" 
                      access="edit"
                      allowAdmin = {isAdmin}
                    />
                  }
                />
                <Route
                  path="/brandedMerchandise"
                  element={
                      <ProtectedRoute
                        element={<BrandedMerchandise />}
                        feature="Branded Merchandise"
                        access="view"
                        allowAdmin={true}
                      />
                  }
                />
                <Route
                  path="/manageCollections"
                  element={
                    <ProtectedRoute
                      element={<ManageCollections />}
                      feature="Manage Collections"
                      access="edit"
                      allowAdmin={isAdmin}
                    />
                  }
                />
                <Route
                  path="/create-edit-collection"
                  element={
                    <ProtectedRoute
                      element={<CreateCollection />}
                      feature="Create Collection"
                      access="edit"
                      allowAdmin={isAdmin}
                    />
                  }
                />
                <Route
                  path="/brandedMerchandise/collections/:collectionName"
                  element={
                    <ProtectedRoute
                      element={<CollectionProductList />}
                      feature="Collection Product List"
                      access="edit"
                      allowAdmin={true}
                    />
                  }
                  component={CollectionProductList}
                />
                <Route
                  path="/brandedMerchandise/collections/:collectionName/:productId"
                  element={
                    <ProtectedRoute
                      element={<ProductDetailsPage />}
                      feature="Product Details Page"
                      access="edit"
                      allowAdmin={true}
                    />
                  }
                />
                <Route
                  path="/brandedMerchandise/my-list"
                  element={
                    <ProtectedRoute 
                      element={<BMListLayout />}
                      feature="Branded Merchandise" 
                      access="view"
                      allowAdmin = {true}
                    />
                  }
                />
                <Route
                  path="/add-edit-product"
                  element={
                    <ProtectedRoute
                      element={<AddProduct />}
                      feature="Add Product"
                      access="edit"
                      allowAdmin={isAdmin}
                    />
                  }
                />
                <Route path="/notification" element={<AllNotification/>} />
                <Route
                  path="/Page-under-construction"
                  element={<PageUnderCons />}
                />
                <Route path="*" element={<Navigate to="/" replace={false} />} />
              </Routes>
            </Layout>
            </SnackbarProvider>
          </UserContext.Provider>
        </NameContext.Provider>
      </StatusContext.Provider>
    </div>
  );
}
export default App;
