import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Divider,
  Checkbox,
  Pagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "../../Common/CommonSearchBar";
import LinearListItem from "./LinearListItem";
import "../BrandedMerchandise.scss";
import { getProductTableData } from "../BMService";

const rowsPerPage = 10;

const BrowseModal = ({
  show,
  handleCloseModal,
  selectedProducts,
  updateSelectedProducts,
}) => {
  const [productList, setProductList] = useState([]);
  const [tempSelectedProducts, setTempSelectedProducts] = useState([
    ...selectedProducts,
  ]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); 

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); 
    }, 500); 

    return () => {
      clearTimeout(handler); 
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const res = await getProductTableData({
          rowsPerPage: 100,
          page: 1,
          searchFilter: debouncedSearchTerm, 
        });
        setProductList(res?.data?.data?.rows);
      } catch (err) {
        console.error('Error fetching products:', err);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchProducts();
    }
    setTempSelectedProducts([...selectedProducts]);
    return () => {
      setPage(1);
    };
  }, [selectedProducts, show, debouncedSearchTerm]); 

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); 
  };

  const handleSubmitModal = () => {
    updateSelectedProducts(tempSelectedProducts);
    handleCloseModal();
  };

  const handleCancelModal = () => {
    handleCloseModal(); 
  };

  const handleChange = (pdt) => {
    const isAlreadySelected = tempSelectedProducts.some(product => product.itemMasterID === pdt.itemMasterID);

    if (isAlreadySelected) {
      setTempSelectedProducts(prevState =>
        prevState.filter(product => product.itemMasterID !== pdt.itemMasterID)
      );
    } else {
      setTempSelectedProducts(prevState => [...prevState, pdt]);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {show && (
        <Box className="browse-modal-overlay">
          <Box className="browse-modal-content">
            <div className="browse-modal-header">
              <Typography>Products</Typography>
              <IconButton onClick={handleCloseModal} className="close-icon">
                <CloseIcon />
              </IconButton>
            </div>

            <div className="browse-modal-body">
              <SearchBar
                placeholder="Search Products"
                value={searchTerm} 
                onChange={handleSearchChange} 
              />
              <Divider className="modal-divider" />
              <div className="modal-product-list">
                {productList
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((product) => (
                    <div key={product.itemMasterID}>
                      <div
                        className="product-item"
                        onClick={() => handleChange(product)}
                      >
                        <Checkbox
                          className="custom-checkbox"
                          checked={tempSelectedProducts.some(p => p.itemMasterID === product.itemMasterID)}
                          onChange={(e) => e.stopPropagation()}
                        />
                        <LinearListItem product={product} />
                      </div>
                      <Divider className="product-divider" />
                    </div>
                  ))}
              </div>
              <div className="pagination-container">
                <Pagination
                  count={Math.ceil(productList.length / rowsPerPage)}
                  page={page}
                  onChange={handlePageChange}
                  size="small"
                />
              </div>
            </div>

            <Box className="button-container">
              <Button size="small" className="done-btn" onClick={handleSubmitModal}>
                Done
              </Button>
              <Button size="small" className="cancel-btn" onClick={handleCancelModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BrowseModal;
