import { React, useEffect, useState, useRef } from "react";
import {flushSync} from 'react-dom'
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import FilterLayout from "./FilterLayout";
import ScrollableSubCategory from "./ScrollableSubCategory";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack } from "@mui/material";
import { addItemToList } from './PBCatalogSlice';
import { shelfLife, shelfLife2 } from "../../../Constants/Constant";
import { pb_service, app_url } from "../../../config/environment";
import getHierarchyDetail from "../../../Utils/GetHierarchyDetail";
import Grid from '@mui/material/Grid';
import { resValidation } from "../../../Utils/CommonUtils";
import UnpublishConfirmation from "../Common/UnpublishConfirmation";
import { unpublishItem } from "./PBCatalogService";
import { useSnackbar } from "notistack";
import ProductCard from "../Common/ProductCard";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import axios from "axios";
import NoDataFallback from "../Common/NoDataFallback";
import DisclaimerUtil from "../Common/DisclaimerUtil";
import LoadingIndicator from "../Common/LoadingIndicator";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";
import { checkExpiryAndRedirect } from "../../../Utils/CommonUtils";

const Wrapper = styled.div`
  padding: 10rem 4rem 0rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  .MuiGrid-container{
    margin-top: 3rem;
    margin-bottom: 10rem;
  }

  @media (min-width: 1521px) {
    /* padding-left: 10vw;
    padding-right: 10vw; */
  }
  @media (max-width: 770px) {
    padding: 10rem 2rem 0rem 2rem;
  }
`;
const FilterSubCatWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const SubCatLayout = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const SubCatDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 3rem;
  height: 100%;
  width: 100%;
`;
const SubCatHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 0.7rem;
`;
const SubCatHeading = styled.div`
  display: flex;
  /* flex-direction: column;
  justify-content: flex-start; */
  align-items: center;
  padding-bottom: 0.5rem;
  margin-left: 0rem;
  font-weight: 500;
  font-size: 1.55rem;
`;
const HighlightedSubCat = styled.div`
  padding: 0 0.25rem;
  background: #FFEEBF;
  font-weight: 600;
`;
const SeeAllContainer = styled.p`
  color: #147350;
  /* float: right; */
  /* text-decoration: underline; */
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 1rem;
  margin-bottom: 0.1rem;

  @media (max-width: 770px) {
    margin-bottom: 0.2rem;
  }
`;
const SubCatContent = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const FilteredSubCat = styled(SubCatContent)`
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  scroll-behavior: smooth;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* leaving some space for gap */
  row-gap: 2rem;

  /* @media (max-width: 1200px) {
    gap: 2rem;
  }
  @media (max-width: 1000px) {
    gap: 1rem;
  } */
  @media (max-width: 770px) {
    grid-template-columns: repeat(2, 1fr); /* leaving some space for gap */
    /* gap: 3rem; */
  }
`;
const ItemDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 14rem;

  .MuiButton-startIcon{
    margin-right: 2px;
    margin-left: 0px;
  }
  .MuiButton-outlined{
    margin: 0.5rem 0.5rem 0 0;
    color: #34a07c; 
    width: auto;
    font-size: 0.75rem !important;
    align-items: center;
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
  }

  @media (max-width: 1200px) {
    width: 11rem;
  }
  @media (max-width: 1000px) {
    width: 9rem;
  }
  @media (max-width: 770px) {
    width: 14rem;
  }
`;
const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: white;
  /* border: 0.3px solid rgba(144, 144, 144, 0.3); */
  border-radius: 0.25rem;
  /* box-shadow: rgba(135, 134, 134, 0.35) 0px 3px 3px; */
  max-width: 100%;
  cursor: pointer;
`;
const SubCatCardImg = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const ItemNameContainer = styled.div`
  margin-top: 0.7rem;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  min-height: 3rem;
  font-weight: 500;
  cursor: pointer;
`;

// let pageType = "SubCatList";
let page = 1;

const SubCategoryLayout = (props) => {
  const navigate = useNavigate();
  const [psaData, setPsaData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [subCategoryDetail, setSubCategoryDetail] = useState([]);
  const [subCategoryHeader, setSubCategoryHeader] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);
  const [pageType, setPageType] = useState("SubCatList");
  const [selectedCategoryName, setSelectedCategoryName] = useState([]);
  const [selectedShelfLife, setSelectedShelfLife] = useState([]);
  const [showUnpublishModal, setShowUnpublishModal] = useState(null);
  const [unpublishResp, setUnpublishResp] = useState("");
  const { countryName, psaName } = useParams();
  const [totalCount, setTotalCount] = useState(0);
  const observer = useRef();
  const decodedPsaName= decodeURIComponent(psaName);

  const dispatch = useDispatch();
  const {myList=[]} = useSelector(state => state.pbCatalog);
  const { enqueueSnackbar } = useSnackbar();

  const activeUsername = localStorage.getItem("userpermission");
  const expTime = localStorage.getItem("expiry_time");

  // const searchApi = `${pb_service}/api/itemmaster/search?noOfRecords=15&pageNo=${page}&status=both&sortBy=itemOriginID&sortDirection=DESC`;

  useEffect(() => {
    const psaId = localStorage.getItem("psa_id");
	if(pageType === "SubCatList") {
		let emptyArr = [];
		setSelectedCategoryName(emptyArr);
		if(selectedShelfLife.length) {
			let filterPayload = [];
			filterPayload = getShelfLifeFilterPayload(filterPayload, selectedShelfLife);
			const shelfLifeParams = encodeURI(JSON.stringify(filterPayload[0]));
			fetchSubCatData(psaId, shelfLifeParams);
		}
		else {
			mySubCatData();
		}
	}
  }, [pageType]);

useEffect(() => {
	if (categoryDetail.length === 2) {
		setPageType("SubCatList");
    } else if (categoryDetail.length === 3) {
		setPageType("SubCat");
    }
}, [categoryDetail.length]);

useEffect(() => {
  observer.current = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  if (observer.current && lastElementRef.current) {
    observer.current.observe(lastElementRef.current);
  }

  return () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };
}, [subCategoryDetail]);

const handleIntersection = (entries) => {
  const target = entries[0];
  if (target.isIntersecting  && subCategoryDetail.length < totalCount) {
    page += 1;
    handleSeeSubCat(subCategoryHeader.name, psaData, selectedShelfLife);
  }
};

const mySubCatData = async() => {
	const psaId = localStorage.getItem("psa_id");
	const psaResponse = await fetchSubCatData(psaId);
	const categoryList = psaResponse.map(obj => obj.categoryDescription);
	setCategoryList(categoryList);
}
  const getShelfLifeFilterPayload = (filterPayload, selectedShelfLife) => {
	let shelfLifeObj = [];
	selectedShelfLife.map(ele => {
		let res = shelfLife2.find(obj => obj.label === ele);
		if (res) {
			shelfLifeObj.push(res);
		}
	});
	shelfLifeObj.forEach(obj => {
		filterPayload.push({
			field: "shelfLife",
			value: obj.targetDays,
			operator: obj.operator,
		})
	});
	/* const shelfLifeRangeArr = shelfLifeObj.map(obj => [obj.startDate, obj.endDate]);
	if(pageType === 'SubCat') {
		shelfLifeRangeArr.forEach((range, i) => {
			filterPayload.push(
				{
					field: "shelfLife",
					value: [range[0], range[1]-1],
					operator: "between",
				}
			);
		});
	}
	else if(pageType === 'SubCatList') {
		shelfLifeRangeArr.forEach((range, i) => {
			filterPayload.push(
				{
					startDate: range[0],
					endDate: range[1]-1,
				}
			);
		});
	} */
	return filterPayload;
  }

  const fetchSubCatData = async (psaId, shelfLifeParams) => {
	if(!shelfLifeParams) {
		shelfLifeParams = null;
	}
    setIsLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
	    url: `${pb_service}/api/itemmaster/itemsByCategories/?noOfRecords=4&itemHierarchyCodeId=${psaId}&shelfLife=${shelfLifeParams}`,
    };
    let psa = [];
    await axios.request(config)
    .then((response) => {
      let validatedRes = resValidation(response);
      psa= validatedRes.data.data;
      // const groupedData= processResponse(psa);
      const queryString = window.location.search;

		if (queryString) {     
			const params = new URLSearchParams(queryString);
			const subcat = params.get('subcat');

			if (subcat.length) {
        page=1;
				handleSeeSubCat(subcat,psa);
			}
		} else {
			setCategoryDetail([countryName,decodedPsaName]);
			//  setSubCategoryDetail(groupedData);
		}

		setPsaData(psa);
      // setCategoryDetail([countryName,decodedPsaName]);
      setSubCategoryDetail(psa);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
      setApiFailReported(true);
    });
	return psa;
  };

  const searchAPICall = async(filters) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${pb_service}/api/itemmaster/search?noOfRecords=15&pageNo=${page}&status=both&sortBy=itemOriginID&sortDirection=DESC&filters=${filters}`,
      };

      return await axios.request(config);
  }

  const filterHandler = async (selectedCategoryName, selectedShelfLife) => {
    page=1;

	  setSelectedCategoryName(selectedCategoryName);
	  setSelectedShelfLife(selectedShelfLife);
	  let filterPayload = [{
		  field: "itemHierarchyCodeId",
		  value: "",
		  operator: "equal",
	  }];
	  if (pageType === 'SubCatList') {
		  let psaResponse = [];
		  const psaId = localStorage.getItem("psa_id");
		  if (selectedCategoryName.length === 0 &&  selectedShelfLife.length === 0) {
			  fetchSubCatData(psaId);
			  return;
		  }else {
			  let filteredList = [];
			  //ShelfLife Filter
			  if(selectedShelfLife.length) {
				  filterPayload = [];
				  filterPayload = getShelfLifeFilterPayload(filterPayload, selectedShelfLife);
				  const shelfLifeParams = encodeURI(JSON.stringify(filterPayload[0]));
				  psaResponse = await fetchSubCatData(psaId ,shelfLifeParams);
			  }else {
				  psaResponse = await fetchSubCatData(psaId);
			  }
			  if(selectedCategoryName.length) {
				  selectedCategoryName.forEach(categoryName => {
					  let tempArr = psaResponse.filter(obj => obj.categoryDescription === categoryName);
					  filteredList = [...filteredList, ...tempArr];
				  });
				  setSubCategoryDetail(filteredList);
			  }
		  }
	  }
	  else if (pageType === 'SubCat') {

		  if(selectedShelfLife.length) {
			  const hierarchyCodeId = subCategoryHeader.id;
			  filterPayload[0].value = hierarchyCodeId;
			  filterPayload = getShelfLifeFilterPayload(filterPayload, selectedShelfLife);
			  const filterPayloadURI = encodeURI(JSON.stringify(filterPayload));
			  if(pageType === 'SubCat') {
				  // let config = {
				  // method: 'get',
				  // maxBodyLength: Infinity,
				  // url: `${searchApi}&filters=${filterPayloadURI}`,
				  // };

				  setIsLoading(true);
          searchAPICall(filterPayloadURI)
				  // await axios.request(config)
				  .then(response => {
            let validatedRes = resValidation(response);
					  const subCatDetail = validatedRes.data.data.rows;
            if(page === 1){
              setSubCategoryDetail(subCatDetail);
              setTotalCount(validatedRes.data.data.count);
            } else {
              setSubCategoryDetail(prevItems => [...prevItems, ...subCatDetail]);
            }
					  // setSubCategoryDetail(subCatDetail);
					  setCategoryDetail([countryName,psaName,subCategoryHeader.name]);
					  setIsLoading(false);
				  })
				  .catch((error) => {
					  console.error("SubCategoryLayout.filterHandler() -> Error", error);
					  setIsLoading(false);
					  setApiFailReported(true);
				  })
			  }
		  }else if(selectedShelfLife.length === 0) {
			  handleSeeSubCat(selectedCategoryName[0], psaData)
		  }
	  }
  }

  const handleSeeSubCat = async (category,psa, selectedShelfLife) => {
    const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
    setPageType("SubCat");
    const subCatClicked= psa.find(obj => obj.categoryDescription === category);
	  setIsLoading(true);
	  let filterPayload = [{
		  field: "itemHierarchyCodeId",
		  value: "",
		  operator: "equal",
	  }];
	  filterPayload[0].value = subCatClicked.categoryID;
	  if(selectedShelfLife && selectedShelfLife.length) {
		  filterPayload = getShelfLifeFilterPayload(filterPayload, selectedShelfLife);
	  }
	  const filterPayloadURI = encodeURI(JSON.stringify(filterPayload));  
	  // let config = {
	  // method: 'get',
	  // maxBodyLength: Infinity,
	  // url: `${searchApi}&filters=${filterPayloadURI}`,
	  // };

    searchAPICall(filterPayloadURI)
	  // await axios.request(config)
	  .then(response => {
		  let validatedRes = resValidation(response);
		  const subCatDetail= validatedRes.data.data.rows;
      if(page === 1){
        setSubCategoryDetail(subCatDetail);
        setTotalCount(validatedRes.data.data.count);
      } else {
        setSubCategoryDetail(prevItems => [...prevItems, ...subCatDetail]);
      }
		  let header = {};
		  if(!subCatDetail.length) {
			  header={name: categoryList[0], id:subCatClicked.categoryID};
		  }
		  else {
			  header=getHierarchyDetail(subCatDetail[0]?.itemHierarchies,2);
		  }
		  setSubCategoryHeader(header);
		  // setSubCategoryDetail(subCatDetail);
		  setCategoryDetail([countryName,psaName,header.name]);
		  setIsLoading(false);
	  })
	  .catch((error) => {
		  console.error("SubCategoryLayout.filterHandler() -> Error", error);
		  setIsLoading(false);
		  setApiFailReported(true);
	  })
  };
  
  const handleSeeCategory = () => {
    setPageType("SubCatList");
    setIsLoading(true);
    setSubCategoryDetail(psaData);
    setCategoryDetail([countryName,decodedPsaName]);
    setIsLoading(false);
  };

  const handleUnpublishBtnClick = (itemOriginID) => {
    setShowUnpublishModal(itemOriginID);
  };

  const displayToastMsg = (toastObj) => {
    enqueueSnackbar(toastObj.message, {
        variant: toastObj.variant,
        preventDuplicate: true,
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });
  };

  const handleUnpublishConfirmation = (selectedBtn) => {
    const itemID=showUnpublishModal;
    setShowUnpublishModal(false);
    if (selectedBtn === "cancel") {
      return;
    }
    unpublishItem(itemID)
      .then((response)=>{
        setUnpublishResp("success");
        const isAdded=isItemAdded(itemID)
        if(isAdded){
          handlePdtToList(parseInt(itemID), isAdded);
        };
        handleSeeSubCat(subCategoryHeader.name,psaData,selectedShelfLife);
        return displayToastMsg({message: "Product unpublished successfully", variant: 'success'});
      })
      .catch((error)=>{
        console.error(error);
        setUnpublishResp("fail");
        return displayToastMsg({message: "Something went wrong", variant: 'error'});
      })
  };

  const handleCardClick = (psaName, subCat, itemID, itemName) => {
    const encodedPSA = encodeURIComponent(psaName);
    const encodedSubCat = encodeURIComponent(subCat);
    navigate(`/privateBrandCatalog/${countryName}/${encodedPSA}/${encodedSubCat}/${itemID}`);
  };

  const isItemAdded = (itemId) => {
    return myList.some(item => item === itemId);
  };

  const handlePdtToList = (item,itemIsAdded) => {
    let updatedList;
    if (itemIsAdded) {
       updatedList = myList.filter(listItem => listItem !== item);
    } else {
       updatedList = [...myList, item];
    }
    dispatch(addItemToList(updatedList));
  };

  const lastElementRef = useRef(null);

  return (
    <Wrapper>
		{isLoading && <LoadingIndicator enableBackdrop={true}/>}
      <PBCHeader
        categoryDetail={categoryDetail}
        handleSeeSubCat={handleSeeSubCat}
        handleSeeCategory={handleSeeCategory}
        pageType={pageType}
        subcatDetails= {subCategoryHeader}
      />
      <DisclaimerUtil></DisclaimerUtil>
      {apiFailReported ?
      <CommonErrorFallback/> :
		<Grid 
			container
			direction="row"
			justifyContent="flex-start"
			alignContent="flex-start"
		>
			<Grid item xs={2} md={2}>
				<FilterLayout 
					categoryList={categoryList} 
					categoryDetail={categoryDetail} 
					categoryFilters={selectedCategoryName}
					shelfLifeFilters={selectedShelfLife}
					onFilterClick={filterHandler}
				/>
			</Grid>
			{(pageType === "SubCatList" ? (
			<Grid item xs={10} md={10}>
				<SubCatLayout>
					{subCategoryDetail.length ? subCategoryDetail.map((item,index) => {
					return (
						<SubCatDetail key={`${item.categoryDescription}-${index}`}>
							<SubCatHeader>
								<SubCatHeading><HighlightedSubCat>{item.categoryDescription}</HighlightedSubCat></SubCatHeading>
								<SeeAllContainer
									onClick={() => {
                    page=1;
										handleSeeSubCat(item.categoryDescription,psaData, selectedShelfLife);
									}}
								>
									View All
								</SeeAllContainer>
							</SubCatHeader>
							<SubCatContent>
								<ScrollableSubCategory
									cardDetail={item}
									categoryId={psaName}
									subCatId={item.categoryDescription}
									fetchSubCatData={fetchSubCatData}
									psaId={localStorage.getItem("psa_id")}
									shelfLifeFilterPayload={selectedShelfLife.length ? getShelfLifeFilterPayload([], selectedShelfLife) : null}
								/>
							</SubCatContent>
						</SubCatDetail>
					);
					}) :
					(isLoading ? "" : <NoDataFallback msg={"No products meet this criteria"}/>)
					}
				</SubCatLayout>
			</Grid>
			) : (
			<Grid item xs={10} md={10}>
				<SubCatLayout style={{paddingBottom: "2rem"}}>
					<SubCatHeading style={{paddingBottom: "1.2rem"}}><HighlightedSubCat>{subCategoryHeader.name}</HighlightedSubCat></SubCatHeading>
					{subCategoryDetail.length ?
					<><FilteredSubCat>
						{subCategoryDetail.map((item) => {
						const itemIsAdded= isItemAdded(item.itemOriginID)
						return (
							<ProductCard 
							item={item}
							itemIsAdded={itemIsAdded}
							handleUnpublishBtnClick={handleUnpublishBtnClick}
							handlePdtToList={handlePdtToList}
							>  
							</ProductCard>
						);
						})}
					</FilteredSubCat> 
          <div ref={lastElementRef}></div>
          </>:
					(isLoading ? "" : <NoDataFallback msg={"No products meet this criteria"} />)
					}
				</SubCatLayout>
			</Grid>
			))}
		</Grid>
		}
    <UnpublishConfirmation show={showUnpublishModal} handleUnpublishConfirmation={handleUnpublishConfirmation}/>
    <ResolutionDisclaimer />
	</Wrapper>
  );
};
export default SubCategoryLayout;
