const timelineData = [
  {
    key: 1,
    year: "1927",
    headline: "World’s First C-Store​",
    details:
      "From the dock of a icehouse in Dallas, Texas, the world’s first convenience store is born.​",
  },
  {
    key: 2,
    year: "1950-1960",
    headline: "Beyond Texas",
    details:
      "The one-stop shopping locations offer everything consumers need, including gas. New stores open in Florida, Maryland, Virginia, and Pennsylvania.​",
  },
  {
    key: 3,
    year: "1963",
    headline: "All Night Long​",
    details:
      "A 7‑Eleven location near a university in Austin stays open all night to accommodate students. The 24/7 idea is a hit and soon catches on in other locations.​",
  },
  {
    key: 4,
    year: "1965",
    headline: "The Drink Revolution​",
    details:
      "It starts with the launch of the Slurpee® drink and the world’s first coffee to go.​",
  },
  {
    key: 5,
    year: "1969",
    headline: "Canada",
    details:
      "Canada opens 1st store - the first international location for 7-Eleven",
  },
  {
    key: 6,
    year: "1970",
    headline: "The Self-Service Movement",
    details:
      "7‑Eleven leads the way, offering self-serve gas and the first self-serve soda fountain. Americans are also introduced to the Big Gulp® fountain drink.",
  },
  {
    key: 7,
    year: "1971",
    headline: "Mexico​",
    details: "1st store in MEXICO opens",
  },
  {
    key: 8,
    year: "1974",
    headline: "Japan",
    details: "1st store in JAPAN opens",
  },
  {
    key: 9,
    year: "1977",
    headline: "Australia",
    details: "1st store in AUSTRALIA opens",
  },
  {
    key: 10,
    year: "1978",
    headline: "Hawaii",
    details: "1st store in HAWAII opens",
  },
  {
    key: 11,
    year: "1978",
    headline: "Sweden",
    details: "1st store in SWEDEN opens",
  },
  {
    key: 12,
    year: "1980",
    headline: "Taiwan",
    details: "1st store in TAIWAN opens",
  },
  {
    key: 13,
    year: "1981",
    headline: "Hong Kong",
    details: "1st store in HONG KONG opens",
  },
  {
    key: 14,
    year: "1983",
    headline: "Singapore",
    details: "1st store in SINGAPORE opens",
  },
  {
    key: 15,
    year: "1984",
    headline: "Malaysia",
    details: "1st store in MALAYSIA opens",
  },
  {
    key: 16,
    year: "1984",
    headline: "Philippines",
    details: "1st store in PHILIPPINES opens",
  },
  {
    key: 17,
    year: "1986",
    headline: "Norway",
    details: "1st store in NORWAY opens",
  },
  {
    key: 18,
    year: "1989",
    headline: "South Korea",
    details: "1st store in SOUTH KOREA opens",
  },
  {
    key: 19,
    year: "1989",
    headline: "Thailand",
    details: "1st store in THAILAND opens",
  },
  {
    key: 20,
    year: "1993",
    headline: "Denmark",
    details: "1st store in DENMARK opens",
  },
  {
    key: 21,
    year: "1992",
    headline: "Guangdong",
    details: "1st store in GUANGDONG opens",
  },
  {
    key: 22,
    year: "2004",
    headline:
      "China (except Hong Kong, Guangdong, Macau and Taiwan which are operated under a separate license agreement)",
    details: "1st store in CHINA opens",
  },
  {
    key: 23,
    year: "2005",
    headline: "Macau",
    details: "1st store in MACAU opens",
  },
  {
    key: 24,
    year: "2017",
    headline: "Viet Nam",
    details: "1st store in VIET NAM opens",
  },
  {
    key: 25,
    year: "2021",
    headline: "Cambodia",
    details: "1st store in CAMBODIA opens",
  },
  {
    key: 26,
    year: "2021",
    headline: "India",
    details: "1st store in INDIA opens",
  },
  { key: 27, year: "Coming soon", headline: "Laos", details: "" },
  { key: 28, year: "Coming soon", headline: "Israel", details: "" },
];
const faqsList = [
  {
    key: 1,
    ques: "Where does the name 7-Eleven come from?",
    ans: "In 1946 the name was changed to 7-Eleven to reflect new extended hours – 7am to 11pm, seven days a week.",
  },
  {
    key: 2,
    ques: "Why is there a lowercase n in the 7-Eleven logo and design?",
    ans: "It is believed that the wife of the company’s president during the 1960s thought the look of the name in the logo ‘7-ELEVEN’ seemed a little harsh with ALL CAPS, and she suggested that the N be changed to lower case to make the logo look more graceful” accordiong to Margaret Chabris, Public Relations Director at 7-Eleven headquarters in Dallas, Texas.",
  },
  {
    key: 3,
    ques: "How many 7-Elevens are in North America?",
    ans: "Currently 7-Eleven has over 9800 stores. With the acquisition of Speedway, 7-Eleven will have approximately 14000 stores in the USA and Canada.",
  },
  {
    key: 4,
    ques: "How many 7-Elevens are there worldwide?",
    ans: "7-Eleven is the world's largest convenience and 7‑Eleven operates, franchises and/or licenses over 82000 stores in 20 countries around the world.",
  },
  {
    key: 5,
    ques: "Which countries have 7-Eleven?",
    ans: "7-Eleven currently operates in 20 Countries - United States, Canada, Mexico, Japan, Thailand, South Korea, Taiwan, China (including Hong Kong), the Philippines, Australia, Singapore, Malaysia, Vietnam, United Arab Emirates, Norway, Sweden, Denmark, India, Cambodia. Laos & Israel COMING SOON!",
  },
  {
    key: 6,
    ques: "What time does 7-Eleven open/close?",
    ans: "Most stores are open 24 hours.",
  },
  {
    key: 7,
    ques: "Are all 7-Elevens open 24 hours?",
    ans: "7-Eleven was the first convenience store to remain open 24/7, and the majority of store are open 24 hours, subject to store and regional or national events like holidays, extreme weather or pandemics.",
  },
  {
    key: 8,
    ques: "When is Slurpee® day?",
    ans: "7-Eleven day, also known as Slurpee® day, is held annually on July 11.",
  },
  {
    key: 9,
    ques: "When is BYOC day?",
    ans: "BYOC or Bring Your Own Cup day allows Slurpee® drink fans can fill any “cup” – or containers that can be used as cups – with Slurpee® deliciousness. That includes thermoses, cookie jars, ladles, punchbowls and other containers that are thirsting to be filled with their favorite Slurpee® flavor. All cups must be leak-proof, safe, sanitary and fit upright within the in-store BYOC display cutout, which is about 10 inches in diameter.",
  },
  {
    key: 10,
    ques: "Who owns 7-Eleven?",
    ans: "7-Eleven is a privately held company, currently owned by Tokyo Based Seven & I Holdings Co., Ltd.",
  },
  {
    key: 11,
    ques: "Where is 7-Eleven headquarters located?",
    ans: "There are 2 Headquarters locations in Tokyo, Japan and Irving, TX United States.",
  },
];

export const countryNames = [
  "Country",
  "Cambodia",
  "China",
  "Taiwan",
  "Laos",
  "India",
  "Japan",
  "Malaysia",
  "Singapore",
  "South Korea",
  "Philippines",
  "Thailand",
  "Vietnam",
  "Norway",
  "Denmark",
  "Sweden",
  "Israel",
  "United States",
  "Hawaii",
  "Canada",
  "Mexico",
  "Australia",
];
export const userManagementCountryList = [
  "Australia",
  "Cambodia",
  "China",
  "Denmark",
  "Hong Kong",
  "India",
  "Israel",
  "South Korea",
  "Japan",
  "Malaysia",
  "Norway",
  "Philippines",
  "Singapore",
  "Sweden",
  "Taiwan",
  "Thailand",
  "USA",
  "Vietnam",
  "Canada",
  "Mexico",
  "Hawaii",
  "Laos",
];

export const countryUrl = {
  Cambodia:
    "https://franchise.7-eleven.com/franchise/international/countries-available#",
  China:
    "https://franchise.7-eleven.com/franchise/international/countries-available#",
  Taiwan: "https://www.7-11.com.tw/",
  Laos: "https://franchise.7-eleven.com/franchise/international/countries-available#",
  India:
    "https://franchise.7-eleven.com/franchise/international/countries-available#",
  Japan: "https://www.sej.co.jp/",
  Malaysia: "https://www.7eleven.com.my/",
  Singapore: "http://www.7-eleven.com.sg/",
  "South Korea":
    "https://www.lotte.co.kr/global/en/business/compDetail.do?compCd=L204",
  Philippines: "https://www.7-eleven.com.ph/",
  Thailand: "https://www.7eleven.co.th/",
  Vietnam: "https://www.7-eleven.vn/",
  Norway: "https://7-eleven.no/",
  Denmark: "https://www.7-eleven.dk/",
  Sweden: "https://7-eleven.se/",
  Israel:
    "https://franchise.7-eleven.com/franchise/international/countries-available#",
  "United States":
    "https://franchise.7-eleven.com/franchise/home?utm_source=corp&utm_medium=referral&utm_campaign=owned-properties&utm_term=header-franchising&_ga=2.213744404.1690156959.1674493470-1203767077.1654721648",
  Hawaii: "https://7elevenhawaii.com/",
  Canada: "https://7-eleven.ca/",
  Mexico: "https://7-eleven.com.mx/",
  Australia: "https://www.7eleven.com.au/",
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const shelfLife = [
	{
		label: "0 - 1 Week",
		startDate: 0,
		endDate: 7,
	},
	{
		label: "1 - 2 Weeks",
		startDate: 7,
		endDate: 14,
	},
	{
		label: "2 - 4 Weeks",
		startDate: 14,
		endDate: 30,
	},
	{
		label: "1 - 2 Months",
		startDate: 30,
		endDate: 60,
	},
	{
		label: "2 - 3 Months",
		startDate: 60,
		endDate: 90,
	},
	{
		label: "3 - 6 Months",
		startDate: 90,
		endDate: 180,
	},
	{
		label: "6 - 12 Months",
		startDate: 180,
		endDate: 360,
	},
	{
		label: "12 - 18 Months",
		startDate: 360,
		endDate: 540,
	},
	{
		label: "18 - 24 Months",
		startDate: 540,
		endDate: 720,
	},
	{
		label: "24 - 36 Months",
		startDate: 720,
		endDate: 1080,
	}
  ];

  export const shelfLife2 = [
	{
		label: "0 - 3 months",
		targetDays: 90,
		operator: "<",
	},
	{
		label: "3 months +",
		targetDays: 90,
		operator: ">=",
	},
	{
		label: "4 months +",
		targetDays: 120,
		operator: ">=",
	},
	{
		label: "5 months +",
		targetDays: 150,
		operator: ">=",
	},
	{
		label: "6 months +",
		targetDays: 180,
		operator: ">=",
	}
  ];

  const userManagementMsgs = {
	addUserSuccess: "User added successfully!",
	addUserFailure: "Unable to add the user, Please try again.",
	editUserSuccess: "User updated successfully!",
	editUserFailure: "Unable to update the user, Please try again.",
	deleteUserSuccess: "User deleted successfully!",
	deleteUserFailure: "Operation Failed, Please try again...",
	activateUserSuccess: "User activated successfully!",
	deactivateUserSuccess: "User deactivated successfully!",
	activateUserFailure: "Operation failed, Please try again later!",
	resetUserSuccess: "Sent out a mail to reset password, User can reset password through the link received in the mail!",
	resetUserFailure: "Operation failed, Please try again later!",
  };

  const logoDASubFolder = ["7-Eleven Master Brand Logo",  "Slurpee", "Private Brand", "7Now", "7Rewards", "Miscellaneous"];

  const accessFeatureList = [
    "Brand Standards",
    "Social Media Assets",
    "Workshop Materials",
    "Pop Signage",
    "Training Material",
    "Newsletter",
    "Private Brand Catalog",
  ];

  const collectionTableHeader = [
    {
      headerName: "COLLECTION IMAGE",
      field: "collectionImage",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 250,
      renderCell: (params) => (
        <img src={params.value} alt="img" className="table-item-image" />
      ),
    },
    {
      headerName: "COLLECTION",
      field: "collection",
      sortable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "NO. OF PRODUCTS",
      field: "numberOfProducts",
      sortable: true,
      flex: 0.5,
      minWidth: 250,
    }
  ];

  const productTableHeader = [
    {
      headerName: "PRODUCT IMAGE",
      field: "productImage",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params) => (
        <img src={params.value} alt="img" className="table-item-image" />
      ),
    },
    {
      headerName: "PRODUCT NAME",
      field: "productName",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "STYLE",
      field: "style",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "PRICE (USD)",
      field: "priceUSD",
      sortable: true,
      flex: 0.5,
      minWidth: 150,
    },
    {
      headerName: "SRP (USD)",
      field: "srpUSD",
      sortable: true,
      flex: 0.5,
      minWidth: 150,
    },
  ];

  const brandedMerchandiseMsgs = {
    editCollectionSuccess: "Collection updated successfully",
    editCollectionFailure: "Unable to update the Collection, Please try again.",
    createCollectionSuccess: "Collection created successfully!",
	  createCollectionFailure: "Unable to create the collection, Please try again.",
    deleteCollectionSuccess: "Collection deleted successfully!",
    deleteCollectionFailure: "Unable to delete the collection, Please try again.",
    addProductSuccess: "Product added successfully!",
	  addProductFailure: "Unable to add the product, Please try again.",
    editProductSuccess: "Product updated successfully!",
	  editProductFailure: "Unable to update the product, Please try again.",
    deleteProductSuccess: "Product deleted successfully!",
    deleteProductFailure: "Unable to delete the product, Please try again.",
    emailSuccess: "Email Sent Successfully",
    emailFailure: "Unable to Send the Email, Please try again."
  }

  const rowsPerPageCount = [5, 10, 25, 50];
  const defaultRowCount = 5;

export {
  timelineData,
  faqsList,
  countries,
  shelfLife,
  userManagementMsgs,
  logoDASubFolder,
  accessFeatureList,
  collectionTableHeader,
  productTableHeader,
  brandedMerchandiseMsgs,
  rowsPerPageCount,
  defaultRowCount
};
