import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./IntBrand.css";
import LayoutComponent from "../../Layout/LayoutComponent";
import { useTranslation } from "react-i18next";
import pic1 from "../../../assets/images/int_brand_growing.jpeg";
import pic2 from "../../../assets/images/Ribbon-Cutting.jpg";
import pic3 from "../../../assets/images/international_brand_growing_03.jpeg";
import pic4 from "../../../assets/images/international_brand_growing_04.jpeg";
import pic5 from "../../../assets/images/international_brand_growing_05.jpeg";
import video from "../../../assets/videos/Home-page-render.mp4";
import { SlideCard } from "../../Layout/SlideCard";
import { ReverseSlideCard } from "../../Layout/SlideCard";
import "react-vertical-timeline-component/style.min.css";
import { Link } from "react-router-dom";
import pic6 from "../../../assets/images/international_brand_header.jpeg";
import { useNavigate } from "react-router-dom";
import CardCarousel from "../../../Utils/CardCarousel";

const IntBrand = () => {
  const { t } = useTranslation();
  const [contactIsOpen, setContactIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contact-us`);
  };

  const CarouselContent = [
    {
      content: pic1,
      heading: `${t("int_heading1")}`,
      description1: `${t("int_description1")}`,
    },
    {
      content: pic2,
      heading: `${t("int_heading2")}`,
      description1: `${t("int_description2")}`,
    },
    {
      content: pic3,
      heading: `${t("int_heading3")}`,
      description1: `${t("int_description3")}`,
    },
    {
      content: pic4,
      heading: `${t("int_heading4")}`,
      description1: `${t("int_description4")}`,
    },
    {
      content: pic5,
      heading: `${t("int_heading5")}`,
      description1: `${t("int_description5")}`,
    },
  ];

  const openContact = () => {
    setContactIsOpen(true);
  };

  return (
    <div className="int-brandstory">
      <div className="int-page-header">
        <h1>{t("international_header")}</h1>
      </div>
      <nav className="int-about-nav">
        <NavLink className="int-text-link" to="/intbrand" end>
          {t("Int_Brand")}
        </NavLink>
        <NavLink className="int-text-link" to="/business-model">
          {t("Business_Model")}
        </NavLink>
        <NavLink className="int-text-link" to="/int-countries">
          {t("Countries_and_regions")}
        </NavLink>
      </nav>
      <br />
      <br />
      <br />
      <section className="carouselContainer">
        <CardCarousel contents={CarouselContent} />
      </section>
      <br />
      <section className="int-brandstory-vision">
        <div>
          <div className="int-brandstory-stripe"></div>
        </div>
      </section>

      <br />
      {/* <LayoutComponent> */}
      <div
        className="int-bg-home-video-container"
        style={{ position: "relative" }}
      >
        <video src={video} loop autoPlay muted />
        <button className="next-button-brand floating-btn-brand">
          <Link to="/business-model" className="next-button-link">
            {t("int_next_business")}
          </Link>
        </button>

        <div className="floating-div-brand">
          <h2>{t("ready_to_franchise")}</h2>
          <p></p>
          <h2>{t("int_internationally")}</h2>
          <button className="bottomBtn" onClick={handleClick}>
            {t("contact_us")}
          </button>
        </div>
      </div>
      {/* </LayoutComponent> */}
    </div>
  );
};
export default IntBrand;
