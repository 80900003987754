import React, { useState } from "react";
import { SlideCard } from "../components/Layout/SlideCard";
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styled from "styled-components";
import { Paper, Button } from "@mui/material";

const Wrapper = styled.div`
    .css-yxvsop {
        overflow: visible;
    }
    .css-gkc4vh {
        top: -9rem;
        overflow: visible;
    }
    .css-1abc02a {
        top: calc(50% + 0.3rem);
        left: 24rem;
    }
    .css-hn784z {
        top: calc(50% + 0.3rem);
        left: 3rem;
    }
    .css-1m9128y {
        left: 13rem;
        top: 1rem;
    }
    .int-brandstory-flex {
        .MuiPaper-root {
            padding: 0.5rem 0 0.5rem 0;
        }
        .MuiIconButton-root:hover {
            background-color: none;
        }
    }
    .MuiIconButton-root {
        opacity: 0.6;
        /* background-color: #147350; */
    }
    .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
        color: #147350;
    }  
`;

const CardCarousel = (props) => {

    return (
        <Wrapper>
            <Carousel
                indicators={true}
                sx={{
                    zIndex: '0',
                    alignContent: 'center',
                    width: '-webkit-fill-available'
                    // height: `${window.innerHeight - 110}px`
                }}
            >
                {
                    props.contents.map((content, i) => {
                        return (
                            <section className="int-brandstory-flex">
                                <Paper
                                    sx={{
                                        background: 'rgb(227, 119, 57, 0.3)',
                                        borderRadius: '16px'
                                    }}
                                >
                                    <SlideCard
                                        photo={content['content']}
                                        heading={content['heading']}
                                        description1={content['description1']}
                                    />
                                </Paper>
                            </section>
                        )
                    })
                }
            </Carousel>
        </Wrapper>
    )
}

export default CardCarousel;