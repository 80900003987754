import React from "react";
import video from "../../assets/videos/Home-page-render.mp4";
import FullPageCarousel from "../../Utils/FullPageCarousel";
import { useTranslation } from "react-i18next";
import image1 from "../../assets/images/Laos update new.png"
import image2 from "../../assets/images/17 slide.png"
import "./Home.css";

const Home = () => {

  const { t } = useTranslation();

  let contents = [
    {
      'headings': `${t("int_brand_quote1")} ${t("int_brand_quote2")}`,
      'description': [`${t("int_brand_quote_name")}`, ''],
      'content': video
    },
    {
      'headings': `1st store in Laos opens September 2023. 7-Eleven’s 20th Country/Region`,
      'description': [``, ``],
      'content': image1
    },
    {
      'headings': ``,
      'description': [``, ``],
      'content': image2
    }
  ]

  return (
    <div>
      {/* <div className="founder-quote">
        <h2>
        "{t("int_brand_quote1")} {t("int_brand_quote2")}"
        </h2>
        <p>{t("int_brand_quote_name")}</p>
        <p>{t("int_brand_quote_desgn")}</p>
      </div> */}
      <div className="bg-home-video-container">
        <FullPageCarousel contents={contents} />
        {/* <video src={video} loop autoPlay muted /> */}
      </div>
    </div>
  );
};

export default Home;
