import React, { useRef, useEffect, useState, forwardRef } from "react";
import "./Esg.css";
import video from "../../../assets/videos/business_video_vimeo.mp4";
import LayoutComponent from "../../Layout/LayoutComponent";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
} from "@mui/material";
import { BsFileEarmark, BsFolder, BsArrowLeft } from "react-icons/bs";
import fileSizeCheck from "../../../Utils/fileSizeCheck";
import { FiDownload } from "react-icons/fi";
import styled from "styled-components";
import MuiAlert from "@mui/material/Alert";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import {
  resValidation,
  sanitizeContentToRender,
} from "../../../Utils/CommonUtils";
import { get_report, view_report } from "../../../config/environment";

var qs = require("qs");
var axios = require("axios");


const ModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 1rem;
  padding-top: 2px;
  width: auto;
  max-width: 80vw;
  max-height: 85vh;
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  .table-cell-cls {
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Roboto Condensed", sans-serif;
  }
  button {
    cursor: pointer;
  }
`;
const ModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
`;

const StyledTableContainer = styled(TableContainer)`
  max-height: 60vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(246, 246, 246);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dcdbdb;
    border-radius: 1px;
  }
`;

const CloseButton = styled(AiFillCloseCircle)`
  height: 2rem;
  width: 1rem;
  background: white;
  color: #dfdcdc;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -0.3rem;
  background-color: transparent;
`;
const TableCellStyled = styled(TableCell)`
  background-color: rgb(246, 246, 246);
  word-break: break-word;
  min-width: 3rem;
`;
const TableRowStyled = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

const FileIcon = styled(BsFileEarmark)`
  padding-right: 10px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());
  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, onVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}
// const view_report = process.env.REACT_APP_VIEW_REPORT;
// const get_report = process.env.REACT_APP_ESG_GET_REPORT;
var config = {
  method: "post",
  url: get_report,
  headers: {},
};

const Esg = () => {
  const esgVidRef = useRef(null);
  const isVisible = usePageVisibility();
  if (!isVisible) esgVidRef.current.pause();

  const checkAccessToken = localStorage.getItem("access_token");

  const [reportsModalOpen, setReportsModalOpen] = useState(false);
  const [esgRespFiles, setEsgRespFiles] = useState([]);
  const [esgRespFolders, setEsgRespFolders] = useState([]);
  const [folderName, setFolderName] = useState("Sustainability Round-Up Reports");
  const [currentFolderPath, setCurrentFolderPath] = useState(
    "Workshop-Materials/Sustainability Round-Up Reports"
  );
  const [folderStructure, setFolderStructure] = useState({});
  const [snackbar, setSnackbar] = useState(false);
  const [playBtn, setPlayBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handlePlay = () => {
    setPlayBtn(false);
    esgVidRef.current.play();
  };
  const handlePause = () => {
    setPlayBtn(true);
    esgVidRef.current.pause();
  };
  const handleToggleVideo = () =>
    esgVidRef.current.paused ? handlePlay() : handlePause();

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        esgVidRef.current.pause();
        setPlayBtn(true);
      }
    });
  };
  const observer = new IntersectionObserver(handleIntersect, {
    threshold: 0.5,
  });
  useEffect(() => {
    observer.observe(esgVidRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const getEsgData = () => {
    setIsLoading(true);
    axios(config)
      .then((response) => {
        let validatedRes = resValidation(response);
        if (validatedRes) {
          const resRootFolders =
            validatedRes.data.body.message.folderStructure.rootFolders;
          setFolderStructure(resRootFolders);
          setFolderContent(
            resRootFolders,
            "Workshop-Materials/Sustainability Round-Up Reports"
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbar(true);
        setReportsModalOpen(false);
        setIsLoading(false);
      });
  };

  const setFolderContent = (structure, folderPath) => {
    let folderContent = structure;
    const pathParts = folderPath.split("/");

    for (let part of pathParts) {
      if (folderContent && folderContent[part]) {
        folderContent = folderContent[part];
      }
    }

    if (folderContent) {
      setEsgRespFiles(folderContent.files || []);
      const folders = Object.keys(folderContent).filter(
        (key) => key !== "files"
      );
      setEsgRespFolders(folders);
      setCurrentFolderPath(folderPath);
      setFolderName(pathParts[pathParts.length - 1]);
    }
  };

  const signedUrl = async (key) => {
    try {
      const sanitizedKey = sanitizeContentToRender(key);
      const res = await fetch(view_report, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          key: sanitizedKey,
          accessToken: checkAccessToken,
        }),
      });
      const data = await res.json();
      window.open(data.body.message.url, "_blank");
    } catch (err) {}
  };
  const navigateToParentFolder = () => {
    const pathParts = currentFolderPath.split("/");
    if (pathParts.length > 1) {
      pathParts.pop();
      const parentFolderPath = pathParts.join("/");
      setFolderContent(folderStructure, parentFolderPath);
    }
  };

  const ToggleAlert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  let ESGFolderContent = (
    <>
      {esgRespFiles.map((row) => (
        <TableRowStyled key={row.File_Name}>
          <TableCellStyled component="th" scope="row">
            <FileIcon />
            {row.File_Name?.indexOf("=_") !== -1
              ? row.File_Name?.split("=_")[1]
              : row.File_Name?.substring(row.File_Name.indexOf("_") + 1)}
          </TableCellStyled>

          <TableCellStyled align="left">{row.LastModified}</TableCellStyled>
          <TableCellStyled align="left">
            {fileSizeCheck(row.Size)}
          </TableCellStyled>
          <TableCellStyled align="center">
            <button
              value="download"
              onClick={(e) => {
                e.preventDefault();
                signedUrl(row.Key);
              }}
            >
              <FiDownload />
            </button>
          </TableCellStyled>
        </TableRowStyled>
      ))}
      {esgRespFolders.map((folder) => (
        <TableRowStyled
          key={folder}
        >
          <TableCellStyled
            style={{ cursor: "pointer" }}
            onClick={() =>
              setFolderContent(folderStructure, `${currentFolderPath}/${folder}`)
            }
          >
            <BsFolder style={{ paddingRight: "10px" }} />
            {folder}
          </TableCellStyled>
          <TableCellStyled align="left">-</TableCellStyled>
          <TableCellStyled align="left">
            -
          </TableCellStyled>
          <TableCellStyled align="center">
            -
          </TableCellStyled>
        </TableRowStyled>
      ))}
    </>
  );

  return (
    <>
      <LayoutComponent>
        <div className="esg-container">
          <div className="esg-video-container">
            {playBtn && (
              <PlayCircleFilledWhiteOutlinedIcon
                style={{
                  width: "3rem",
                  height: "auto",
                }}
                className="playBtn"
                onClick={() => {
                  handlePlay();
                }}
              />
            )}
            <video
              src={video}
              loop
              ref={esgVidRef}
              onClick={handleToggleVideo}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="esg-text-container">
            <div className="esg-text">
              <p style={{ fontSize: "2rem", fontWeight: "800" }}>WHO WE ARE</p>

              <p> Environmental, Social and Governance</p>
              <br />
              <p style={{ fontSize: "1rem" }}>
                Environmental, social and governance (ESG) considerations are
                integrated into the strategies and values that govern our
                business and our commitment to inclusive, sustainable growth
              </p>
            </div>
          </div>
        </div>
      </LayoutComponent>
      <div className="esg-bottom-band">
        <LayoutComponent>
          <div className="esg-button-container">
            <p style={{ fontSize: "2rem", fontWeight: "800" }}>ESG REPORTS</p>
            <p>Find sustainability reports from our global partners.</p>
            <button
              className="esg-button"
              onClick={() => {
                setReportsModalOpen(true);
                getEsgData();
              }}
            >
              READ REPORTS
            </button>
          </div>
        </LayoutComponent>
      </div>
      <ModalWrapper
        show={reportsModalOpen && !snackbar}
        onClick={() => {
          setReportsModalOpen(false);
        }}
      >
        <ModalBody
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            {/* <AiFillCloseCircle
              style={{
                height: "2rem",
                width: "1rem",
                background: "white",
                color: "#dfdcdc",
                cursor: "pointer",
                position: "absolute",
                top: "0",
                right: "-0.3rem",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setReportsModalOpen(false);
              }}
            /> */}
            <CloseButton
              onClick={() => {
                setReportsModalOpen(false);
              }}
            />
            {currentFolderPath.split("/").length > 2 && (
              <BackButton onClick={navigateToParentFolder}>
                <BsArrowLeft style={{ marginRight: "0.5rem" }} />
              </BackButton>
            )}
            <div className="esg-modal-body">
              <ModalTitle>{folderName}</ModalTitle>
              <StyledTableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#147350 !important",
                        color: "white",
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>Files</TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Last modified
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>Size</TableCell>
                      <TableCell sx={{ color: "white" }}>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  {!isLoading && <TableBody>{ESGFolderContent}</TableBody>}
                  {isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCellStyled colSpan={4}>
                          <div
                            style={{
                              minHeight: "5rem",
                              width: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              color="success"
                              style={{ color: "#147350" }}
                            />
                          </div>
                        </TableCellStyled>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </StyledTableContainer>
            </div>
          </div>
        </ModalBody>
      </ModalWrapper>
      <Snackbar
        id="esgPopup"
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar(false)}
        key={"top" + "right"}
      >
        <ToggleAlert
          onClose={() => setSnackbar(false)}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          Some error occurred.
        </ToggleAlert>
      </Snackbar>
    </>
  );
};

export default Esg;
