import React, { useState, useEffect, useCallback } from "react";
import debounce from 'lodash.debounce';
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
  IconButton,
  Button,
  Autocomplete,
  Popper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowBackIos } from "@mui/icons-material";
import CustomFileUpload from "../../Common/CustomFileUpload/CustomFileUpload";
import BrowseModal from "./BrowseModal";
import LinearListItem from "./LinearListItem";
import "../BrandedMerchandise.scss";
import { EditCollection, createCollection, getBMProductDetails } from "../BMService";
import { brandedMerchandiseMsgs } from "../../../../Constants/Constant";
import { checkExpiryAndRedirect, uploadImageToS3 } from '../../../../Utils/CommonUtils';
import { useSnackbar } from "notistack";
import LoadingIndicator from '../../Common/LoadingIndicator';
import { getProductTableData } from '../BMService';
import BMDeletePopup from './BMDeletePopup'
import { pb_service } from "../../../../config/environment";

let isDataUpdated = false;
const MAX_CHAR_LIMIT = 50;

const CreateCollection = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { returnUrl = '/manageCollections', collectionObj = {} } = state || {};

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const editCollectionObj = state?.collectionObj || {};
  const isEditCollection = !!Object.keys(editCollectionObj).length;
  const [initialProductList, setInitialProductList] = useState([]);
  const [formData, setFormData] = useState({
    collectionTitle: isEditCollection ? editCollectionObj.description : '',
    collectionImage: isEditCollection ? editCollectionObj.hierarchyUrl : [],
    resourceUuid: isEditCollection ? editCollectionObj.resourceUuid : null,
    productList: [], 
    uploadedImageData: null,
  });
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [requiredFieldError, setRequiredFieldError] = useState({});
  const [showBrowseModal, setShowBrowseModal] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [showDeleteUtil, setShowDeleteUtil] = useState(false);
  const [deletePdt, setDeletePdt] = useState(null);

  const FetchProducts = async (productIds) => {
    try {
      const response = await getBMProductDetails({ productIds: productIds, rowsPerPage: 500, page: 1 });
      const products = response?.data?.data?.rows || [];
      setSelectedProducts(products);
      setInitialProductList(products);
      setFormData((prev) => ({ ...prev, productList: products }));
    } catch (error) {
      console.error("Error fetching products: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchedProducts = async (searchQuery) => {
    try {
      setLoading(true);
      const response = await getProductTableData({searchFilter: searchQuery,rowsPerPage: 5,page: 1,});
      const products = response?.data?.data?.rows || [];
      setProductOptions(products);
    } catch (error) {
      console.error("Error fetching products: ", error);
    } finally {
      setLoading(false);
    }
  };

  const getFilteredProductOptions = () => {
    return productOptions.filter(
      (option) => !selectedProducts.some((product) => product.itemMasterID === option.itemMasterID)
    );
  };

  const debouncedFetchProducts = useCallback(
    debounce((query) => {
      fetchSearchedProducts(query);
    }, 500), 
    []
  );

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery) {
      debouncedFetchProducts(searchQuery); 
    }
  };

  useEffect(() => {
    fetchSearchedProducts('');
  }, []);

  useEffect(() => {
    if (isEditCollection) {
      const productIds = editCollectionObj.itemHierarchies.map(item => item.itemMasterId);
      FetchProducts(productIds);
    }
  }, [isEditCollection]);
  
  
  useEffect(() => {
    return () => {
      isDataUpdated = false;
    };
  }, []);


  useEffect(() => {
    setFormData((prev) => ({ ...prev, productList: selectedProducts }));
  }, [selectedProducts]);

  useEffect(() => {
    const { collectionTitle, collectionImage, productList, uploadedImageData } =
      formData;
    const isProductImageAvailable = Boolean(
      uploadedImageData || collectionImage.length
    );
    const isProductListChanged =
      productList.length !== initialProductList.length ||
      JSON.stringify(productList.map((p) => p.itemMasterID)) !==
        JSON.stringify(initialProductList.map((p) => p.itemMasterID));
    const isFormChanged =
      collectionTitle.trim() !== (editCollectionObj?.description || "") ||
      uploadedImageData ||
      collectionImage !==
        (isEditCollection ? editCollectionObj.hierarchyUrl : []) ||
      isProductListChanged;
    const isFormValid = collectionTitle.trim() !== "";
    setIsSaveDisabled(!(isFormChanged && isFormValid));
  }, [formData, initialProductList]);

  const handleCloseBrowseModal = () => {
    setShowBrowseModal(false);
  };

  const handleUpdateSelectedProducts = (newSelectedProducts) => {
    setSelectedProducts(newSelectedProducts); 
  };

  const handleRemoveProduct = (itemMasterID) => {
    setSelectedProducts((prevState) => {
      const updatedProducts = prevState.filter((product) => product.itemMasterID !== itemMasterID);
      setFormData((prev) => ({ ...prev, productList: updatedProducts })); 
      return updatedProducts;
    });
  };

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const errors = { ...requiredFieldError };

    if (value.trim() === "") {
      errors[name] = "This field is required and cannot be left empty";
    } else {
      errors[name] = "";
    }

    setRequiredFieldError(errors);
  };

  const handleFileUpload = (fileObject) => {

    // this block is triggered if file removed from dropzone
    if(!fileObject) {
      setFormData((prev) => ({
        ...prev,
        uploadedImageData: null,
        collectionImage: []
      }))
      return;
    }

    setFormData((prev) => ({
      ...prev,
      uploadedImageData: fileObject.file
    }));
  };

  const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};



  const handleSaveCollection = async () => {
    const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
    setLoading(true);

    try {
      /* in case of editCollection, this will always be either a URL string or undefined.
      for create, will always be undefined */
      let [imageUrl] = formData.collectionImage;

      // if any new image file selected, reset imageUrl value to be new file name.
      if (formData.uploadedImageData) {
        imageUrl = await uploadImageToS3(
          `${pb_service}/api/branded-merchandise/preSignedUrl/putObject`,
          formData.uploadedImageData,
          'collection',
          isEditCollection ? formData.resourceUuid : null
        );
      }

      const itemMasterIDs = selectedProducts.map((product) => product.itemMasterID);
      const editPayload = {
        id: editCollectionObj.id,
        description: formData.collectionTitle,
        hierarchyUrl: imageUrl ? [imageUrl] : [],
        itemMasterIDs,
      };

      const createPayload = {
        description: formData.collectionTitle,
        hierarchyUrl: imageUrl ? [imageUrl] : [],
        itemMasterIDs
      };

      let response;
      if (isEditCollection) {
        response = await EditCollection(editPayload);
        displayToastMsg({
          message: brandedMerchandiseMsgs.editCollectionSuccess,
          variant: "success",
        });
      } else {
        response = await createCollection(createPayload);
        displayToastMsg({
          message: brandedMerchandiseMsgs.createCollectionSuccess,
          variant: "success",
        });
      }
      navigate(returnUrl);
    } catch (error) {
      const toastMsg = error.response?.data?.message || brandedMerchandiseMsgs.createCollectionFailure;
      if (isEditCollection) {
        displayToastMsg({
          message: error.response?.data?.message || brandedMerchandiseMsgs.editCollectionFailure,
          variant: "error",
        });
      } else {
        displayToastMsg({
          message: toastMsg,
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }

  }

  const handleExitCreateCollection = () => {
    if(isEditCollection){
      navigate(returnUrl);
      setFormData([])
    }else{
      navigate('/manageCollections')
      setFormData([])
    }
  }

  const handleDeleteConfirmation = () => {
    resetDeleteState();
    handleExitCreateCollection();
  };

  const resetDeleteState = () => {
    setDeletePdt(null);
    setShowDeleteUtil(false);
  }

  const handleDeleteCollection = () => {
    setDeletePdt({ collection: editCollectionObj.description, id:  editCollectionObj.id});
    setShowDeleteUtil(true);
  };

  return (
    <div className="layout-wrapper">
      {loading && <LoadingIndicator />}
      <div className="form-wrapper">
        <div className="form-header">
          <div className="form-heading">
            <IconButton onClick={handleExitCreateCollection}>
              <ArrowBackIos />
            </IconButton>
            <h1>
              {isEditCollection ? "Edit Collection" : "Create Collection"}
            </h1>
          </div>
        </div>
        <div className="centre-aligned-form-container">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={<>Title <span style={{ color: 'red' }}>*</span></>}
              placeholder="Title e.g. Summer collection, under $100"
              variant="outlined"
              name="collectionTitle"
              value={formData.collectionTitle}
              onChange={handleTitleChange}
              error={!!requiredFieldError.collectionTitle}
              helperText={requiredFieldError.collectionTitle}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "green",
                  },
                },
              }}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      formData.collectionTitle.length > MAX_CHAR_LIMIT
                        ? "#d32f2f"
                        : "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "green",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px 8px",
                  },
                },
              }}
            />
            <CustomFileUpload imageUrl={editCollectionObj.hierarchyUrl?.[0]} onFileUpload={handleFileUpload} isRequired={false}/>
          </Grid>
        </div>
        <div className="centre-aligned-form-container">
          <Typography variant="body1">Add Products</Typography>
          <Stack
            direction={"row"}
            spacing={0}
            alignItems={"center"}
            sx={{ width: "100%", margin: "1rem 0 0.5rem 0" }}
          >
            <div>
              {/* Commented out the Select Products Autocomplete as requested */}
              {/* 
              <Autocomplete
                multiple
                options={getFilteredProductOptions()}
                getOptionLabel={(option) => option.shortDescription}
                getOptionKey={(option) => option.itemMasterID}
                value={selectedProducts}
                onChange={(event, newValue) => {
                  setSelectedProducts(newValue);
                }}
                renderTags={(value, getTagProps) => {
                  return null;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Products"
                    error={!!requiredFieldError.productList}
                    helperText={requiredFieldError.productList}
                    onChange={handleSearchChange} 
                    size="medium"
                    sx={{
                      width: "13rem",
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "green",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "green",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "green",
                      },
                    }}
                  />
                )}
                disableClearable
                clearOnEscape
                autoHighlight
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        maxHeight: 214,
                        overflow: "auto",
                      },
                    }}
                  />
                )}
              />
              */}
              <BrowseModal
                show={showBrowseModal}
                handleCloseModal={handleCloseBrowseModal}
                updateSelectedProducts={handleUpdateSelectedProducts}
                selectedProducts={selectedProducts}
              />
            </div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowBrowseModal(true)}
            >
              BROWSE PRODUCTS
            </Button>
          </Stack>
          <div>
            {selectedProducts.length ? <Divider sx={{ my: 2 }} /> : <></>}
            {selectedProducts.map((product) => {
              return (
                <Box className="list-container" key={product.itemMasterID}>
                  <LinearListItem product={product} />
                  <IconButton
                    onClick={() => handleRemoveProduct(product.itemMasterID)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              );
            })}
          </div>
        </div>
        <Box className="button-container">
          <Button
            size="medium"
            sx={{
              backgroundColor: isSaveDisabled ? "grey" : "#147350",
              color: "white",
              "&:hover": {
                backgroundColor: isSaveDisabled ? "grey" : "#147350",
                opacity: isSaveDisabled ? 1 : 0.9,
              },
            }}
            disabled={isSaveDisabled}
            className="save-btn"
            onClick={handleSaveCollection}
          >
            {isEditCollection ? "SAVE COLLECTION" : "CREATE COLLECTION"}
          </Button>

          {isEditCollection && (
            <Button
              size="small"
              sx={{
                color: "#D32F2F",
                border: 'solid 1px #D32F2F',
                marginLeft: '1rem'
              }}
              onClick={handleDeleteCollection}
            >
              Delete Collection
            </Button>
          )}
        </Box>
        {showDeleteUtil && (
          <BMDeletePopup row={deletePdt} type={'collections'} onConfirm={handleDeleteConfirmation} onCancel={resetDeleteState} />
        )}
      </div>
    </div>
  );
};

export default CreateCollection;
