import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./IntCountries.css";
import LayoutComponent from "../../Layout/LayoutComponent";
import { useTranslation } from "react-i18next";
import modelValue1 from "../../../assets/images/international_slider_1.jpeg";
import modelValue2 from "../../../assets/images/international_slider_2.jpeg";
import modelValue3 from "../../../assets/images/international_slider_3.jpeg";
import modelValue4 from "../../../assets/images/international_slider_4.jpeg";
import modelValue5 from "../../../assets/images/international_slider_5.jpeg";
import modelValue6 from "../../../assets/images/international_slider_6.jpeg";
import modelValue7 from "../../../assets/images/international_slider_7.jpeg";
import modelValue8 from "../../../assets/images/international_slider_8.jpeg";
import modelValue9 from "../../../assets/images/international_available_header.jpeg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import video from "../../../assets/videos/Home-page-render.mp4";
import Modal from "react-modal";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import "react-vertical-timeline-component/style.min.css";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import { Chart } from "react-google-charts";
import { countryNames, countryUrl } from "../../../Constants/Constant";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  .css-1f8sh1y {
    height: 425px !important;
    width: 720px !important;
  }
`;

const IntCountries = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [contactIsOpen, setContactIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contact-us`);
  };

  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      img: modelValue1,
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
      img: modelValue2,
    },
    {
      name: "Random Name #3",
      description: "Probably the most random thing you have ever seen!",
      img: modelValue3,
    },
    {
      name: "Random Name #4",
      description: "Hello World!",
      img: modelValue4,
    },
    {
      name: "Random Name #5",
      description: "Probably the most random thing you have ever seen!",
      img: modelValue5,
    },
    {
      name: "Random Name #6",
      description: "Hello World!",
      img: modelValue6,
    },
    {
      name: "Random Name #7",
      description: "Probably the most random thing you have ever seen!",
      img: modelValue7,
    },
    {
      name: "Random Name #8",
      description: "Hello World!",
      img: modelValue8,
    },
  ];
  function Item(props) {
    return (
      <Paper>
        <img src={props.item.img} alt="images"></img>
      </Paper>
    );
  }
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openContact = () => {
    setContactIsOpen(true);
  };

  return (
    <div className="int-brandstory">
      <div className="int-page-header">
        <h1>{t("international_header")}</h1>
      </div>
      <nav className="int-about-nav">
        <NavLink className="int-text-link" to="/intbrand" end>
          {t("Int_Brand")}
        </NavLink>
        <NavLink className="int-text-link" to="/business-model">
          {t("Business_Model")}
        </NavLink>
        <NavLink className="int-text-link" to="/int-countries">
          {t("Countries_and_regions")}
        </NavLink>
      </nav>
      <LayoutComponent className="int-brandstory-timeline section">
        <div className="country-brandstory-image">
          <img src={modelValue9} alt="brandstory"></img>
        </div>
      </LayoutComponent>
      <br />
      <br />
      <section className="int-brandstory-vision">
        <div>
          <div className="int-brandstory-vision-quotes">
            <h1>{t("franchise_international")}</h1>
            <h2 style={{ marginTop: "2rem" }}>{t("country_region")}</h2>
            <br />
            <div className="new-existing-container">
              <div style={{ width: "50%" }}>
                <h2>{t("int_new_head")}</h2>
                <p className="new-existing-content">{t("int_new_content")}</p>
                <button className="contact-button" onClick={handleClick}>
                  {t("int_ready")}
                </button>
              </div>
              <div style={{ width: "50%" }}>
                <h2>{t("int_existing")}</h2>
                <p className="new-existing-content">
                  {t("int_existing_content1")}
                </p>
                <p className="new-existing-content">
                  {t("int_existing_content2")}
                  <a
                    target="_blank"
                    href="https://franchise.7-eleven.com/franchise/stores-available"
                    rel="noopener noreferrer"
                    style={{ color: "#147350" }}
                  >
                    {" "}
                    {t("int_existing_here")}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <LayoutComponent className="country-timeline section">
        <div className="country-image-background">
          <Chart
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 0) return;
                  const region = countryNames[selection[0].row + 1];
                  window.open(countryUrl[region], "_blank");
                },
              },
            ]}
            chartType="GeoChart"
            width="100%"
            height="80%"
            data={countryNames.map((item) => [item])}
            options={{
              colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
              backgroundColor: "#20262E",
              datalessRegionColor: "#F7EFE5",
              defaultColor: "#147350",
              domain: "IN",
              animation: {
                startup: true,
                duration: 1500,
              },
            }}
          />
          <div className="country-timeline-row">
            <div className="country-timeline-content">
              <h2>{t("int_existing_countries")}</h2>
              <p>
                <Draggable
                  bounds={{ left: -200, top: 0, right: 200, bottom: 0 }}
                >
                  <button
                    className="contact-button country-list-button"
                    onClick={openModal}
                  >
                    {t("int_list_countries")}
                  </button>
                </Draggable>
                <Modal
                  className="modal"
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                >
                  <div className="modal-content">
                    <div className="titleBtnContainer">
                      <div className="titleCloseBtn">
                        <button
                          onClick={() => {
                            setModalIsOpen(false);
                          }}
                        >
                          x
                        </button>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="leadership-container">
                        <div className="container">
                          <div className="leadership-detail-image"></div>

                          <div className="country-modal-detail">
                            <h2 className="leader_name">
                              {t("global_presence")}
                            </h2>
                            <div className="leadership-detail-resources"></div>
                            <div className="container">
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                  <Grid item md={4}>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      Asia
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Cambodia</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>
                                          China
                                          <ul
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "1rem",
                                            }}
                                          >
                                            <a
                                              style={{
                                                textDecoration: "none",
                                                color: "#147350",
                                              }}
                                              href="https://www.7-eleven.com.hk/en"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <li>Hong Kong/Macau</li>
                                            </a>
                                            <a
                                              style={{
                                                textDecoration: "none",
                                                color: "#147350",
                                              }}
                                              href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <li>Guangzhou (South China)</li>
                                            </a>
                                            <a
                                              style={{
                                                textDecoration: "none",
                                                color: "#147350",
                                              }}
                                              href="https://www.7-11.com.tw/"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <li>Taiwan</li>
                                            </a>
                                            <a
                                              style={{
                                                textDecoration: "none",
                                                color: "#147350",
                                              }}
                                              href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <li>All Other</li>
                                            </a>
                                          </ul>
                                        </li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Laos</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>India</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.sej.co.jp/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Japan</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7eleven.com.my/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Malaysia</li>
                                      </a>
                                    </ul>
                                  </Grid>
                                  <Grid item md={4}>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      Asia Cont.
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="http://www.7-eleven.com.sg/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Singapore</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.lotte.co.kr/global/en/business/compDetail.do?compCd=L204"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>South Korea</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7-eleven.com.ph/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Philippines</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7eleven.co.th/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Thailand</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7-eleven.vn/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Vietnam</li>
                                      </a>
                                    </ul>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      Europe
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://7-eleven.no/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Norway</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7-eleven.dk/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Denmark</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://7-eleven.se/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Sweden</li>
                                      </a>
                                    </ul>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      Middle East/ North Africa
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/international-countries-available#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Israel</li>
                                      </a>
                                    </ul>
                                  </Grid>
                                  <Grid item md={4}>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      North America
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://franchise.7-eleven.com/franchise/home?utm_source=corp&utm_medium=referral&utm_campaign=owned-properties&utm_term=header-franchising&_ga=2.213744404.1690156959.1674493470-1203767077.1654721648"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>United States</li>
                                      </a>
                                      <ul
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        <a
                                          style={{
                                            textDecoration: "none",
                                            color: "#147350",
                                          }}
                                          href="https://7elevenhawaii.com/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <li>Hawaii</li>
                                        </a>
                                      </ul>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://7-eleven.ca/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Canada</li>
                                      </a>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://7-eleven.com.mx/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Mexico</li>
                                      </a>
                                    </ul>
                                    <ul
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                      }}
                                      className="col-md-9"
                                    >
                                      Oceania
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "#147350",
                                        }}
                                        href="https://www.7eleven.com.au/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li>Australia</li>
                                      </a>
                                    </ul>
                                  </Grid>
                                </Grid>
                              </Box>
                            </div>
                            <p>{t("global_quote1")}</p>
                            <p>{t("global_quote2")}</p>
                            <p>{t("global_quote3")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </p>
            </div>
          </div>
        </div>
      </LayoutComponent>
      <br />
      <div className="country-slider">
        <div className="int-brandstory-vision-quotes carousel-heading">
          <h1>{t("int_over_the_globe")}</h1>
          <section
            className="business carouselContainer"
            style={{ paddingTop: "0" }}
          >
            <Wrapper>
              <Carousel
                autoPlay={true}
                interval={3500}
                animation={"slide"}
                stopAutoPlayOnHover={false}
              >
                {items.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Wrapper>
          </section>
        </div>
      </div>
      <section className="corp_contact-us">
        <LayoutComponent>
          <h2 className="text-center">{t("int_social")}</h2>
          <div className="flex">
            <div className="int-section-content">
              <div className="int-section-content-item">
                <h3 style={{ height: "1.5rem" }}>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/7-eleven-international"
                    className="contactCard"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn
                      style={{
                        height: "1.5rem",
                        width: "3rem",
                        color: "#147350",
                      }}
                    />
                  </a>
                </h3>
              </div>
            </div>
            <div className="int-section-content">
              <div className="int-section-content-item">
                <h3 style={{ height: "1.5rem" }}>
                  <a
                    target="_blank"
                    className="contactCard"
                    href="https://www.facebook.com/7ElevenInternational"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF
                      style={{
                        height: "1.5rem",
                        width: "3rem",
                        color: "#147350",
                      }}
                    />
                  </a>
                </h3>
              </div>
            </div>
            <div className="int-section-content">
              <div className="int-section-content-item">
                <h3 style={{ height: "1.5rem" }}>
                  <a
                    target="_blank"
                    className="contactCard"
                    href="https://www.instagram.com/7elevenintl/"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram
                      style={{
                        height: "1.5rem",
                        width: "3rem",
                        color: "#147350",
                      }}
                    />
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </LayoutComponent>
      </section>
      <br />
      {/* <LayoutComponent> */}
      <div
        className="int-bg-home-video-container"
        style={{ position: "relative", marginTop: "0.5rem" }}
      >
        <video src={video} loop autoPlay muted />
        <button className="next-button-countries floating-btn">
          <Link to="/intbrand" className="next-button-link">
            {t("countries_back_to_brand")}
          </Link>
        </button>

        <div className="floating-div">
          <h2>{t("ready_to_franchise")}</h2>
          <p></p>
          <h2>{t("int_internationally")}</h2>
          <button className="bottomBtn" onClick={handleClick}>
            {t("contact_us")}
          </button>
        </div>
      </div>
      {/* </LayoutComponent> */}
    </div>
  );
};
export default IntCountries;
