import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/images/7Eleven-header.png";
import stripes from "../../assets/images/newStripes.png";
import Navigation from "./Navbar/Navigation";
import TopNavigation from "./Navbar/TopNavigation";
import NavItems from "./Navbar/NavItems";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [hamburgerFlag, setHamburgerFlag] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [supModalOpen, setSupModalOpen] = useState(false);

  const openHamburger = (
    <AiOutlineMenu
      className="Hamburger"
      size="40px"
      color="black"
      onClick={() => setHamburgerFlag(true)}
    />
  );

  const closeHamburger = (
    <AiOutlineCloseCircle
      className="Hamburger"
      size="40px"
      color="black"
      onClick={() => setHamburgerFlag(false)}
    />
  );

  const handleUserDetailUpdate = () => {
    props.handleUserDetailUpdate();
  };

  const closeHamMenu = () => {
    setHamburgerFlag(false);
  };

  return (
    <div className="nav">
      <TopNavigation handleUserDetailUpdate={handleUserDetailUpdate} />

      <div className="second-nav">
        <div className="logo-nav">
          {/* <Link to="/">
            <img className="stripes" src={stripes} alt="stripes" height="60" />
          </Link> */}
          <Link to="/">
            <img className="logo" src={logo} alt="Logo" height="70" />
          </Link>
        </div>

        <Navigation
          hamClose={closeHamMenu}
          // contactOpen={setContactModalOpen}
          // checkContactOpen={contactModalOpen}
          // supContactOpen={setSupModalOpen}
          // supCheckContactOpen={supModalOpen}
        />

        <div className="mobile-navigation">
          {hamburgerFlag ? closeHamburger : openHamburger}
        </div>
      </div>

      {hamburgerFlag && (
        <NavItems
          hamClose={closeHamMenu}
          // contactOpen={setContactModalOpen}
          // checkContactOpen={contactModalOpen}
          // supContactOpen={setSupModalOpen}
          // supCheckContactOpen={supModalOpen}
        />
      )}
    </div>
  );
};

export default Header;
