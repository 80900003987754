import React from "react";
import "./SlideCard.css";

const SlideCard = (props) => {
  return (
    <div 
      className="slider"
    >
      <div style={{ marginRight: '1rem' }}>
        <img src={props.photo} alt={props.photo} className="slider-logo" />
      </div>
      <div
        style={{
          textAlign: 'left'
        }}
      >
        <h1 className="slider-h1">{props.heading}</h1>
        <h2 className="slider-h2">{props.description1}</h2>
      </div>
    </div>
  );
};
const ReverseSlideCard = (props) => {
  return (
    <div className="slider" style={{ justifyContent: "flex-end" }}>
      <div>
        <h1 className="slider-h1">{props.heading}</h1>
        <h2 className="slider-h2">{props.description1}</h2>
      </div>
      <div style={{ marginLeft: "5rem" }}>
        <img src={props.photo} alt="{props.photo}" className="slider-logo" />
      </div>
    </div>
  );
};
export { SlideCard, ReverseSlideCard };
