import { React, useState, useEffect } from "react";
import "./ContactUsPage.css";
import { useTranslation } from "react-i18next";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { countries } from "../../Constants/Constant";
import LayoutComponent from "../Layout/LayoutComponent";
import { submit_contact } from "../../config/environment";

var qs = require("qs");

function ContactUsPage(props) {
  const { t } = useTranslation();
  // const submit_contact = process.env.REACT_APP_SUBMIT_CONTACT;
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    country: "",
    company: "",
    email: "",
    interest: "",
    msg: "",
  });
  const [firstNameFocused, setFirstNameFocused] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [companyFocused, setCompanyFocused] = useState(false);
  const [otherFocused, setOtherFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [msgFocused, setMsgFocused] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [resEmail, setResEmail] = useState("Processing...");
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const areaOfInterest = [
    "Food / Beverage",
    "Equipment",
    "Media",
    "Marketing",
    "Available Markets / New Market Entry",
    "7-Eleven Global Technology",
    "Investments",
    "Other",
  ];

  function SubmitButton() {
    if (isValid) {
      return (
        <button
          className="submitBtn"
          onClick={(e) => {
            e.preventDefault();
            submitContact();
          }}
        >
          {t("contactUs_submit")}
        </button>
      );
    } else {
      return (
        <button className="submitBtn-disabled">{t("contactUs_submit")}</button>
      );
    }
  }

  const Validate = () => {
    return (
      !values.firstName.replace(/\s/g, "").length ||
      !values.lastName.replace(/\s/g, "").length ||
      !values.email.includes(".com") ||
      !values.email.includes("@") ||
      !(values.company?.length >= 1) ||
      !(values.country?.length > 1) ||
      !(values.interest?.length > 1) ||
      (otherSelected && values.other?.length > 1)
    );
  };

  useEffect(() => {
    var check = Validate();
    setIsValid(!check);
  }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleFirstNameFocus = (e) => {
    setFirstNameFocused(true);
  };
  const handleLastNameFocus = (e) => {
    setLastNameFocused(true);
  };
  const handleCompanyFocus = (e) => {
    setCompanyFocused(true);
  };
  const handleOtherFocus = (e) => {
    setOtherFocused(true);
  };
  const handleEmailFocus = (e) => {
    setEmailFocused(true);
  };
  const handleMsgFocus = (e) => {
    setMsgFocused(true);
  };

  const submitContact = async () => {
    setSubmitted(true);
    setIsSending(true);
    setIsValid(false);
    try {
      const res = await fetch(submit_contact, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          userName: values.firstName + " " + values.lastName,
          userEmail: values.email,
          userCountry: values.country,
          userCompany: values.company,
          userInterest: values.interest,
          userMessage: values.msg,
        }),
      });
      const data = await res.json();
      const resStatus = await res.status;
      setResEmail(resStatus);
      setIsSending(false);
      setValues({
        firstName: "",
        lastName: "",
        country: "",
        company: "",
        email: "",
        interest: "",
        msg: "",
      });
      setFirstNameFocused(false);
      setLastNameFocused(false);
      setCompanyFocused(false);
      setOtherFocused(false);
      setEmailFocused(false);
      setMsgFocused(false);
    } catch (err) {}
  };

  return (
    <LayoutComponent>
      {!submitted && (
        <div className="contactUsWrapper">
          <div style={{ marginBottom: "3rem" }}>
            <h1>Contact Us</h1>
          </div>
          <div>
            <div>
              <div className="twoFieldRow">
                <div className="inputLabelWrapper">
                  <div className="astrixWrapper">
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>First Name</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div className="contactFormInput">
                    <input
                      className="formInput"
                      type="text"
                      name="firstName"
                      placeholder="Type here..."
                      pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
                      maxLength="255"
                      required
                      onChange={onChange}
                      onClick={handleFirstNameFocus}
                      focused={values.firstName && firstNameFocused.toString()}
                      value={values.firstName}
                    />
                    <span className="formInputError">
                      {t("contactUs_name_warning")}
                    </span>
                  </div>
                </div>
                <div className="inputLabelWrapper">
                  <div className="astrixWrapper">
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Last Name</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div className="contactFormInput">
                    <input
                      className="formInput"
                      type="text"
                      name="lastName"
                      placeholder="Type here..."
                      pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
                      maxLength="255"
                      required
                      onChange={onChange}
                      onClick={handleLastNameFocus}
                      focused={values.lastName && lastNameFocused.toString()}
                      value={values.lastName}
                    />
                    <span className="formInputError">
                      {t("contactUs_name_warning")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="twoFieldRow">
                <div className="inputLabelWrapper">
                  <div className="astrixWrapper">
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Contact Email</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div className="contactFormInput">
                    <input
                      className="formInput"
                      // style={{ marginBottom: "0", marginTop: "0" }}
                      type="email"
                      name="email"
                      placeholder="Type here..."
                      required
                      onChange={onChange}
                      onClick={handleEmailFocus}
                      focused={values.email && emailFocused.toString()}
                      value={values.email}
                    />
                    <span className="formInputError">
                      {t("contactUs_email_warning")}
                    </span>
                  </div>
                </div>
                <div
                  className="inputLabelWrapper"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="astrixWrapper" style={{ marginTop: "4px" }}>
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Country</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div
                    className="contactFormInput"
                    // style={{ marginTop: "0.6rem" }}
                  >
                    <Autocomplete
                      id="country-customized-option-demo"
                      options={countries}
                      getOptionLabel={(option) => `${option.label}`}
                      onChange={(e, selectedOption) => {
                        setValues({
                          ...values,
                          country: selectedOption ? selectedOption.label : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Country"
                          InputLabelProps={{ shrink: false }}
                          name="country"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="twoFieldRow">
                <div className="inputLabelWrapper">
                  <div className="astrixWrapper">
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Company</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div className="contactFormInput">
                    <input
                      className="formInput"
                      type="text"
                      name="company"
                      placeholder="Type here..."
                      pattern="^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$"
                      maxLength="255"
                      required
                      onChange={onChange}
                      onClick={handleCompanyFocus}
                      focused={values.company && companyFocused.toString()}
                      value={values.company}
                    />
                    <span className="formInputError">
                      {t("contactUs_name_warning")}
                    </span>
                  </div>
                </div>
                <div
                  className="inputLabelWrapper"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="astrixWrapper" style={{ marginTop: "4px" }}>
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Area of Interest</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div
                    className="contactFormInput"
                    // style={{ marginTop: "0.6rem" }}
                  >
                    <Autocomplete
                      id="country-customized"
                      options={areaOfInterest}
                      getOptionLabel={(option) => `${option}`}
                      onChange={(e, selectedOption) => {
                        if (selectedOption === "Other") {
                          setOtherSelected(true);
                        } else {
                          setOtherSelected(false);
                          setValues({
                            ...values,
                            interest: selectedOption ? selectedOption : "",
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Area of Interest"
                          InputLabelProps={{ shrink: false }}
                          name="interest"
                          onChange={onChange}
                          required
                        />
                      )}
                      required
                    />
                  </div>
                </div>
              </div>
              {otherSelected && (
                <div className="inputLabelWrapper" style={{ float: "right" }}>
                  <div className="astrixWrapper">
                    <div>
                      <h6 style={{ fontSize: "1rem" }}>Other</h6>
                    </div>
                    <div>
                      <h6 style={{ color: "red" }}>*</h6>
                    </div>
                  </div>
                  <div className="contactFormInput otherInput">
                    <input
                      className="formInput"
                      type="text"
                      name="other"
                      placeholder="Type here..."
                      pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
                      maxLength="255"
                      required
                      onChange={(e) => {
                        setValues({
                          ...values,
                          interest: e.target.value ? e.target.value : "",
                        });
                      }}
                      onClick={handleOtherFocus}
                      focused={values.other && otherFocused.toString()}
                      value={values.other}
                    />
                    <span className="formInputError">
                      {t("contactUs_name_warning")}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="inputLabelWrapper" style={{ width: "100%" }}>
                <div
                  className="astrixWrapper"
                  style={{ marginBottom: "0.8rem" }}
                >
                  <div>
                    <h6 style={{ fontSize: "1rem" }}>Additional Information</h6>
                  </div>
                  <div></div>
                </div>
                <div className="contactFormInput" style={{ width: "100%" }}>
                  <textarea
                    className="formInput msgInput"
                    type="text"
                    name="msg"
                    style={{ marginTop: "0", resize: "none" }}
                    placeholder="Type here..."
                    pattern="^[a-zA-Z0-9_][a-zA-Z0-9_ ]*[a-zA-Z0-9_]$"
                    maxLength="1000"
                    required
                    onChange={onChange}
                    onClick={handleMsgFocus}
                    focused={values.msg && msgFocused.toString()}
                    value={values.msg}
                  />
                  <span className="formInputError">
                    {t("contactUs_msg_warning")}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <SubmitButton />
            </div>
          </div>
        </div>
      )}
      {submitted && (
        <div className="contactUsWrapper">
          <div className="contactFormInput-Res">
            <h1
              className={
                isSending
                  ? `contact-response-black processing-modal-black`
                  : resEmail === 200
                  ? `contact-response-green processing-modal`
                  : `contact-response-red`
              }
            >
              {isSending ? (
                <>{t("processing_contact_res")}</>
              ) : resEmail === 403 ? (
                <>
                  <ImBlocked
                    style={{
                      textAlign: "center",
                      scale: "1",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("invalid_contact_res")}
                </>
              ) : resEmail === 500 ? (
                <>
                  <ImBlocked
                    style={{
                      textAlign: "center",
                      scale: "1",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("wrong_contact_res")}
                </>
              ) : (
                <>
                  <MdOutlineMarkEmailRead
                    style={{
                      textAlign: "center",
                      scale: "1.5",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("sent_contact_res")}
                </>
              )}
              !
            </h1>
          </div>
        </div>
      )}
    </LayoutComponent>
  );
}

export default ContactUsPage;
