import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./About.css";
import pic1 from "../../assets/images/picture1.jpeg";
import LayoutComponent from "../Layout/LayoutComponent";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { AiFillCloseCircle } from "react-icons/ai";

const About = () => {
  const { t } = useTranslation();
  const [storyModalOpen, setStoryModalOpen] = useState(false);

  const openModal = () => {
    setStoryModalOpen(true);
  };

  const closeModal = () => {
    setStoryModalOpen(false);
  };

  return (
    <div className="brandstory">
      <div className="page-header">
        <h1>{t("who_we_are")}</h1>
      </div>
      <nav className="about-nav">
        <NavLink className="text-link" to="/about" end>
          {t("our_brand_story")}
        </NavLink>
        <NavLink className="text-link" to="/leadership">
          {t("our_leadership")}
        </NavLink>
      </nav>
      <section className="brandstory-success">
        <LayoutComponent>
          <h1 className="text-center">
            {t("success_story")}
            <br />
            {t("customer_needs")}
          </h1>
          <div className="brandstory-flex">
            <div className="brandstory-content">
              <p>{t("brandstory_content1")}</p>
              <div className="customBlock"></div>
              <p>{t("brandstory_content2")}</p>
              <div className="customBlock"></div>
              <p>{t("brandstory_content3")}</p>
              <div className="customBlock"></div>
              <p>{t("brandstory_content4")}</p>
              <div className="customBlock"></div>
              <p>{t("brandstory_content5")}</p>
              <div className="customBlock"></div>
            </div>
            <div className="brandstory-img">
              <img src={pic1} alt="images" />
            </div>
          </div>
        </LayoutComponent>
      </section>
      <br />
      <br />
      <section className="brandstory-vision">
        <div>
          <div className="brandstory-vision-quotes">
            <h1>{t("brandstory_vision")}</h1>
            <p>{t("brandstory_quote1")}</p>
            <br />
            <h1>{t("brandstory_mission")}</h1>
            <p>
              {t("brandstory_quote2")}
              <br />
              {t("personalized_convenience")}
            </p>
            <div className="customBlock2"></div>
          </div>
        </div>
      </section>
      <LayoutComponent className="brandstory-timeline section">
        <div className="brandstory-image">
          <div className="brandstory-timeline-row">
            <div className="brandstory-timeline-content">
              <h2>{t("through_years")}</h2>
              <p>
                <button className="about-btn" onClick={openModal}>
                  {t("explore_story")}
                </button>
              </p>
            </div>
          </div>
        </div>
      </LayoutComponent>
      <br />
      <Modal
        className="storyModal"
        isOpen={storyModalOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
      >
        <div className="modal-story-container" dir="ltr">
          <div className="modalBtnContainer">
            <div className="modalCloseBtn timelineCloseBtn">
              <AiFillCloseCircle
                style={{
                  height: "2rem",
                  width: "1.5rem",
                  marginLeft: "2rem",
                  background: "white",
                  color: "#dfdcdc",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStoryModalOpen(false);
                }}
              />
            </div>
          </div>
          <div className="modal-story-body">
            <div className="modal-body-container">
              <h1>{t("through_years")}</h1>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem", 
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year1")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline1")}
                  </h3>
                  <p>{t("details1")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem", 
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year2")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline2")}
                  </h3>
                  <p>{t("details2")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year3")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline3")}
                  </h3>
                  <p>{t("details3")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year4")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline4")}
                  </h3>
                  <p>{t("details4")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year5")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline5")}
                  </h3>
                  <p>{t("details5")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year6")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline6")}
                  </h3>
                  <p>{t("details6")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year7")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline7")}
                  </h3>
                  <p>{t("details7")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year8")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline8")}
                  </h3>
                  <p>{t("details8")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year9")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline9")}
                  </h3>
                  <p>{t("details9")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year10")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline10")}
                  </h3>
                  <p>{t("details10")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year11")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline11")}
                  </h3>
                  <p>{t("details11")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year12")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline12")}
                  </h3>
                  <p>{t("details12")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year13")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline13")}
                  </h3>
                  <p>{t("details13")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year14")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline14")}
                  </h3>
                  <p>{t("details14")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year15")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline15")}
                  </h3>
                  <p>{t("details15")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year16")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline16")}
                  </h3>
                  <p>{t("details16")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year17")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline17")}
                  </h3>
                  <p>{t("details17")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year18")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline18")}
                  </h3>
                  <p>{t("details18")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year19")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline19")}
                  </h3>
                  <p>{t("details19")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year20")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline20")}
                  </h3>
                  <p>{t("details20")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year21")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline21")}
                  </h3>
                  <p>{t("details21")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year22")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline22")}
                  </h3>
                  <p>{t("details22")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year23")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline23")}
                  </h3>
                  <p>{t("details23")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year24")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline24")}
                  </h3>
                  <p>{t("details24")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year25")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline25")}
                  </h3>
                  <p>{t("details25")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year26")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline26")}
                  </h3>
                  <p>{t("details26")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year27")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline27")}
                  </h3>
                  <p>{t("details27")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year28")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline28")}
                  </h3>
                  <p>{t("details28")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year29")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline29")}
                  </h3>
                  <p>{t("details29")}​</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#009E60", lineHeight: "2.5rem",
                    color: "white",
                  }}
                  contentArrowStyle={{
                    borderRight: "0.5rem solid  #009E60",
                  }}
                  date={t("year30")}
                  iconStyle={{
                    background: "#147350",
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                    left: "50%",
                    marginLeft: "-0.5rem",
                    marginTop: "1.5rem",
                  }}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t("headline30")}
                  </h3>
                  <p>{t("details30")}​</p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </Modal>
      <section className="corp_contact-us">
        <LayoutComponent>
          <h1 className="text-center contact-heading">{t("contact_us")}</h1>
          <div className="flex">
            <div className="section-content">
              <div className="section-content-item">
                <h3>
                  <a
                    href="https://corp.7-eleven.com/corp/in-the-news"
                    className="contactCard"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("for_media")} &rarr;
                  </a>
                </h3>
                <p>
                  {t("email_us")}: <br></br>
                  <a className="email" href="mailto:intl-license@7-11int.com">
                    GM-Media7IN@7-11.com
                  </a>
                </p>
              </div>
            </div>
            <div className="section-content">
              <div className="section-content-item">
                <h3>
                  <a
                    className="contactCard"
                    href="https://survey.medallia.com/?711-gr&channel=6&type=2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("for_marketing")} &rarr;
                  </a>
                </h3>
                <p>
                  {t("email_us")}: <br></br>
                  <a className="email" href="mailto:intl-license@7-11int.com">
                    GM-Marketing7IN@7-11.com
                  </a>
                </p>
              </div>
            </div>
            <div className="section-content">
              <div className="section-content-item">
                <h3>
                  <a
                    className="contactCard"
                    href="https://survey.medallia.com/?711-gr&channel=6&type=2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("for_customers")} &rarr;
                  </a>
                </h3>
                <p>
                  {t("email_us")}: <br></br>
                  <a className="email" href="mailto:intl-license@7-11int.com">
                    intl-license@7-11int.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <p className="text-center general-questions">
            {t("general_questions")}: 1-800-255-0711 or{" "}
            <NavLink to="/faqs" className="email">
              FAQs
            </NavLink>
          </p>
        </LayoutComponent>
      </section>
    </div>
  );
};
export default About;
