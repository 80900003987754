import { React } from "react";
import styled from "styled-components";
import { Avatar } from "@mui/material";

const AvatarContainer = styled.div`
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  @media (max-width: 1200px) {
    margin: 0 10px 0 0.5rem;
  }
`;

const Notification = ({ profilePicture, name }) => {
  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name.includes(" ")
          ? name.split(" ")[0][0] + "" + name.split(" ")[1][0]
          : name[0][0]
      }`,
    };
  }

  return (
    <AvatarContainer>
      {profilePicture ? (
        <Avatar
          alt="Profile Picture"
          src={profilePicture}
          style={{
            padding: "0rem",
            margin: "0 0 0 0",
            top: "4px",
            scale: "0.7",
            width: "3.5rem",
            height: "3.5rem",
            border: "1px solid #dedede",
          }}
          onClick={() => {}}
        />
      ) : (
        <Avatar
          {...stringAvatar(String(name.trim()).toUpperCase())}
          style={{
            padding: ".1rem",
            margin: "0 0 0 0",
            top: "5px",
            scale: "0.9",
          }}
          onClick={() => {}}
        />
      )}
    </AvatarContainer>
  );
};
export default Notification;
