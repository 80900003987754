import { React, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import FilterLayout from "./FilterLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addItemToList} from './PBCatalogSlice';
import getItemHierarchy from "../../../Utils/GetItemHierarchy";
import { ItemNameContainer } from "./SubCategoryLayout";
import { pb_service } from "../../../config/environment";
import Grid from '@mui/material/Grid';
import { resValidation } from "../../../Utils/CommonUtils";
import UnpublishConfirmation from "../Common/UnpublishConfirmation";
import NoDataFallback from "../Common/NoDataFallback";
import { unpublishItem } from "./PBCatalogService";
import ProductCard from "../Common/ProductCard";
import { useSnackbar } from "notistack";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import axios from 'axios';
import DisclaimerUtil from "../Common/DisclaimerUtil";
import { encodeUtil } from "../../../Utils/CommonUtils";
import LoadingIndicator from "../Common/LoadingIndicator";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";

const Wrapper = styled.div`
  padding: 10rem 5rem 0rem 5rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
  
  .MuiGrid-container{
    margin-top: 1rem;
    margin-bottom: 10rem;
  }

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
`;
const SubCatLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  
  h2{
    padding-bottom: 2.3rem;
    color: #147350;
  }
`;
const SubCatContent = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const FilteredSubCat = styled(SubCatContent)`
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  scroll-behavior: smooth;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19%, 1fr)); /* leaving some space for gap */
  gap: 3rem;

  @media (max-width: 1200px) {
    gap: 2rem;
  }
  @media (max-width: 1000px) {
    gap: 1rem;
  }
  @media (max-width: 770px) {
    grid-template-columns: repeat(auto-fill, minmax(38%, 1fr)); /* leaving some space for gap */
    gap: 3rem;
  }
`;

const pageType = "SearchPage";
let page = 1;

const SearchLayout = (props) => {
  const navigate = useNavigate();
  const [header, setHeader] = useState("");
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [subCategoryDetail, setSubCategoryDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [showUnpublishModal, setShowUnpublishModal] = useState(null);
  const [queryFilter, setQueryFilter] = useState([]);
  const [unpublishResp, setUnpublishResp] = useState("");
  // const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const observer = useRef();

  const dispatch = useDispatch();
  const {myList=[]} = useSelector(state => state.pbCatalog);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (observer.current && lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [subCategoryDetail]);

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting  && subCategoryDetail.length < totalCount) {
      page += 1;
      handleSearch(queryFilter);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    var query,header;
    if (queryString) {     
      const params = new URLSearchParams(queryString);
      query = params.get('filters');
      setQueryFilter(query);
      header = params.get('header');
      setHeader(header);
      setCategoryDetail([header,"Search"])
    }
    setSearchKey(JSON.parse(query).find(obj => { return obj.field === "shortDescription" || obj.field === "itemOriginID"; }).value.toUpperCase());
    // setPage(1);
    page = 1;
    handleSearch(query);
  }, [window.location.search]); //search in the search page

  const handleSearch = async (query) => {
    setIsLoading(true);
    const encodedQuery = encodeURI(query);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${pb_service}/api/itemmaster/search?noOfRecords=15&pageNo=${page}&status=both&sortBy=itemOriginID&sortDirection=DESC&filters=${encodedQuery}`,
    };
    
    axios.request(config)
    .then((response) => {
      let validatedRes = resValidation(response);
      if(page === 1){
        setSubCategoryDetail(validatedRes.data.data.rows);
        setTotalCount(validatedRes.data.data.count);
      } else {
        setSubCategoryDetail(prevItems => [...prevItems, ...validatedRes.data.data.rows]);
      }
      // setPage(page+1);
      // setSubCategoryDetail(validatedRes.data.data.rows);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
      setApiFailReported(true);
    });

  };

  const handleUnpublishBtnClick = (itemOriginID) => {
    setShowUnpublishModal(itemOriginID);
  };

  const displayToastMsg = (toastObj) => {
    enqueueSnackbar(toastObj.message, {
        variant: toastObj.variant,
        preventDuplicate: true,
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });
  };
  
  const handleUnpublishConfirmation = (selectedBtn) => {
    const itemID=showUnpublishModal;
    setShowUnpublishModal(false);
    if (selectedBtn === "cancel") {
      return;
    }
    unpublishItem(itemID)
      .then((response)=>{
        setUnpublishResp("success");
        const isAdded=isItemAdded(itemID)
        if(isAdded){
          handlePdtToList(parseInt(itemID), isAdded);
        };
        handleSearch(queryFilter);
        return displayToastMsg({message: "Product unpublished successfully", variant: 'success'});
      })
      .catch((error)=>{
        console.error(error);
        setUnpublishResp("fail");
        return displayToastMsg({message: "Something went wrong", variant: 'error'});
      })
  };

  const isItemAdded = (itemId) => {
    return myList.some(item => item === itemId);
  };

  const handlePdtToList = (item,itemIsAdded) => {
    let updatedList;
    if (itemIsAdded) {
       updatedList = myList.filter(listItem => listItem !== item);
    } else {
       updatedList = [...myList, item];
    }
    dispatch(addItemToList(updatedList));
  };

  const lastElementRef = useRef(null);

  return (
    <Wrapper>
		{isLoading && <LoadingIndicator />}
      <PBCHeader
        categoryDetail={categoryDetail}
        header={header}
        pageType={pageType}
      />
      <DisclaimerUtil></DisclaimerUtil>
      {apiFailReported ?
      <CommonErrorFallback/> :
      <Grid 
        container 
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* <Grid item xs={2} md={2}><FilterLayout /></Grid> */}
        <Grid item xs={12} md={12}>
          <SubCatLayout>
            <h2>{`SEARCH RESULT - "${searchKey}"`}</h2>
            {subCategoryDetail.length ?
              <FilteredSubCat>
                {subCategoryDetail.map((item, index) => {
                  const itemIsAdded= isItemAdded(item.itemOriginID);
                  return (
                  <ProductCard 
                    key={index}
                    item={item}
                    itemIsAdded={itemIsAdded}
                    handleUnpublishBtnClick={handleUnpublishBtnClick}
                    handlePdtToList={handlePdtToList}
                  >  
                  </ProductCard>
                  );
                })} 
                <div ref={lastElementRef}></div>
              </FilteredSubCat> :
              (isLoading ? "" : <div style={{display: "flex", justifyContent: "center", width: "100%"}}><NoDataFallback msg={"No products matched the description"}/></div>)
            }
          </SubCatLayout>
        </Grid>
      </Grid>}
      <UnpublishConfirmation show={showUnpublishModal} handleUnpublishConfirmation={handleUnpublishConfirmation}/>
      <ResolutionDisclaimer />
    </Wrapper>
  );
};
export default SearchLayout;
