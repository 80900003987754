import { React, useEffect, useState } from "react";
import styled from "styled-components";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { formatDistanceToNowStrict } from "date-fns";
import Avatar from "../../../Utils/Avatar";
import { useNavigate } from "react-router-dom";

const NotifModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const NotifModalBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  width: 70%;
  max-width: 20rem;
  max-height: 45vh;
  overflow-y: scroll;
  /* top: 50%;
  transform: translateY(-50%); */
  top: 4rem;
  right: 1rem;
  position: absolute;
  background: white;
`;
const NotifModalHeader = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow-y: scroll; */
`;
const NotifViewAllWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.2rem 0.5rem 0.5rem 0rem;
  cursor: pointer;
  overflow-y: scroll;
  max-width: 20rem;
  max-height: 45vh;
`;
const ViewAllContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #147350;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`;
const NotifContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;
const NotifItemWrapper = styled.div`
  /* height: 5rem; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.5rem 0;
`;
const PostDetailContainer = styled.div`
  // width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const ApprovalBtnContainer = styled.div`
  // width: 45%;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 0.6rem;
  margin-right: 0.5rem;
  position: relative;
  top: 8px;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 0rem;
  margin-right: 0.5rem;
  position: relative;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;
const CustomBtn = styled.button`
  width: 2.8rem;
  padding: 0.1rem;
  height: 1.3rem;
  border-radius: 2rem;
  font-size: 0.6rem;
  cursor: pointer;
  margin: 0.5rem;
  margin-right: 0;
  position: relative;
  text-align: center;
  @media (max-width: 1350px) {
    margin: 10px 0 0 0.5rem;
  }
`;
const ApproveBtn = styled(CustomBtn)`
  font-size: 11px;
  width: 3.5rem;
  border: 1px solid #e37739;
  background-color: #e37739;
  color: white;
`;
const RejectBtn = styled(CustomBtn)`
  font-size: 11px;
  width: 3.5rem;
  border: 1px solid #c9c9c9;
  background-color: #c9c9c9;
  color: #7d7d7d;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
`;
const PostContainer = styled.div`
  // width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const NameContainer = styled.div`
  margin: 1rem 0 0;
  @media (max-width: 1350px) {
    margin: 1rem 0 0 0;
  }
`;
const UserName = styled.p`
  font-weight: 550;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
`;
const PreviewContainer = styled.div`
  // margin-top: 0.6rem;
  margin: 0.6rem 0.5rem 0 0;
  cursor: pointer;

  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
const PostPreview = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
`;
const DateContainer = styled.div`
  margin: 0.3rem 0;
`;
const PostDate = styled.p`
  font-size: 13px;
  line-height: 12px;
  color: #979797;
`;
const CompanyName = styled.p`
  font-size: 0.9rem;
  letter-spacing: 0px;
  opacity: 0.75;
  margin-bottom: -8px;
`;

const Notification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const notifData = [
    {
      name: "licence A",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Unpublish",
      status: "pending",
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
    },
    {
      name: "licence C",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Unpublish",
      status: "pending",
    },
    {
      name: "licence D",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "3 days ago",
      action: "Unpublish",
      status: "pending",
    },
    {
      name: "licence E",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
    },
  ];
  let actionBtn;
  actionBtn = (
    <>
      <RejectBtn id="showRejectModal" onClick={(e) => {}}>
        Cancel
      </RejectBtn>
      <ApproveBtn id="showRejectModal" onClick={(e) => {}}>
        Unpublish
      </ApproveBtn>
    </>
  );
  const handleViewAll = () => {
    setShowNotification(false);
    navigate(`/notification`);
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <Badge
        color="error"
        badgeContent={11}
        max={9}
        style={{
          margin: "2rem 1.2rem 0 0",
        }}
        onClick={() => {
          console.log("Notif clicked");
          setShowNotification(true);
        }}
      >
        <NotificationsIcon
          style={{
            width: "2rem",
            height: "auto",
            margin: "-0.3rem",
          }}
        />
      </Badge>
      <NotifModalWrapper show={showNotification}>
        <NotifModalBody>
          <NotifModalHeader>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem",
                paddingBottom: "0rem",
              }}
            >
              <h3 style={{ fontWeight: "600", paddingLeft: "0.5rem" }}>
                Notifications
              </h3>
              <svg
                width="15"
                height="auto"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setShowNotification(false);
                }}
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.75"
                  fill="white"
                  stroke="#D1D1D1"
                  strokeWidth="0.5"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <path
                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                  fill="#FF0000"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </svg>
            </div>
          </NotifModalHeader>
          <NotifViewAllWrapper>
            <NotifContainer>
              {notifData.map((item) => {
                return (
                  <NotifItemWrapper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <PostDetailContainer>
                        <Avatar
                          name={item.name}
                          profilePicture={item?.profilePicture}
                        />
                        <div style={{ marginLeft: "0.4rem" }}>
                          <NameContainer>
                            <UserName>{String(item.name)}</UserName>
                            {/* <CompanyName>{item.name}</CompanyName> */}
                          </NameContainer>
                          <PostContainer id="showPostPopup">
                            <PreviewContainer>
                              {item.text?.length ? (
                                <PostPreview>
                                  <div>{item.text}</div>
                                </PostPreview>
                              ) : (
                                item.postText.length === 0 && (
                                  <PostPreview></PostPreview>
                                )
                              )}
                            </PreviewContainer>
                            <DateContainer>
                              <PostDate>{item.date}</PostDate>
                            </DateContainer>
                          </PostContainer>
                        </div>
                      </PostDetailContainer>
                      <ApprovalBtnContainer>
                        <div style={{ color: "#8E8E8E", fontSize: "0.8rem" }}>
                          <p>Awaiting for Unpublish</p>
                        </div>
                        <BtnContainer>{actionBtn}</BtnContainer>
                      </ApprovalBtnContainer>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="285"
                      height="2"
                      viewBox="0 0 355 2"
                      fill="none"
                      style={{ marginLeft: "0.8rem", marginTop: "0.5rem" }}
                    >
                      <path
                        d="M1 0.75C0.861929 0.75 0.75 0.861929 0.75 1C0.75 1.13807 0.861929 1.25 1 1.25V0.75ZM1 1.25H355V0.75H1V1.25Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </NotifItemWrapper>
                );
              })}
            </NotifContainer>
            <ViewAllContainer
              onClick={() => {
                handleViewAll();
              }}
            >
              View all
            </ViewAllContainer>
          </NotifViewAllWrapper>
        </NotifModalBody>
      </NotifModalWrapper>
    </div>
  );
};
export default Notification;
