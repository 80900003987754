import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import heroImage from "../../../assets/images/BusinessHeroImg.png";
import successDriven from "../../../assets/images/SuccessDrivenImg.png";
import investImage from "../../../assets/images/international_model_invest.png";
import supportImage from "../../../assets/images/international_model_support.png";
import criticalQualification from "../../../assets/images/critical_qualification.png";
import modelValue1 from "../../../assets/images/international_model_value_01_min_270_8364.png";
import modelValue2 from "../../../assets/images/international_model_value_02_min_270_8398.png";
import modelValue3 from "../../../assets/images/international_model_value_03_min_270_1060.png";
import modelValue4 from "../../../assets/images/international_model_value_04_min_270_9216.png";
import modelValue5 from "../../../assets/images/international_model_value_05_min_270_3116.png";
import modelValue6 from "../../../assets/images/international_model_value_06_min_270_9010.png";
import modelValue7 from "../../../assets/images/international_model_support_01_min_270_1182.jpeg";
import globalImpact from "../../../assets/videos/business_video_vimeo.mp4";
import { useTranslation } from "react-i18next";
import "./Business.css";
import { Link } from "react-router-dom";
import LayoutComponent from "../../Layout/LayoutComponent";

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());
  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, onVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

const Business = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const videoRef = React.useRef(null);

  const isVisible = usePageVisibility();
  if (!isVisible) videoRef.current.pause();

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    });
  };
  const observer = new IntersectionObserver(handleIntersect, {
    threshold: 0.5,
  });
  useEffect(() => {
    observer.observe(videoRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="int-brandstory">
      <div className="int-page-header">
        <h1>{t("international_header")}</h1>
      </div>
      <nav className="int-about-nav">
        <NavLink className="int-text-link" to="/intbrand" end>
          {t("Int_Brand")}
        </NavLink>
        <NavLink className="int-text-link" to="/business-model">
          {t("Business_Model")}
        </NavLink>
        <NavLink className="int-text-link" to="/int-countries">
          {t("Countries_and_regions")}
        </NavLink>
      </nav>
      <LayoutComponent>
        <div className="hero-image">
          <img alt="business model" src={heroImage}></img>
        </div>
      </LayoutComponent>
      <div className="blockGap"></div>
      <section className="business">
        <LayoutComponent>
          <div className="business-grow-container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <div className="image-container">
                    <img alt="success driven" src={successDriven}></img>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="text-container">
                    <h2 className="business-header">
                      {t("business_How_We_Help")}
                    </h2>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className="business-para"
                          variant="h6"
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            "@media screen and (max-width: 450px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {t("business_int_development")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            "@media screen and (max-width: 450px)": {
                              fontSize: ".95rem",
                              margin: "-1rem .5rem .5rem",
                            },
                          }}
                        >
                          {t("business_provide_support")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className="business-para"
                          variant="h6"
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            "@media screen and (max-width: 450px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {t("business_Operations")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            "@media screen and (max-width: 450px)": {
                              fontSize: ".95rem",
                              margin: "-1rem .5rem .5rem",
                            },
                          }}
                        >
                          {t("business_helps_you_develop")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className="business-para"
                          variant="h6"
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            "@media screen and (max-width: 450px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {t("business_merchandising")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            "@media screen and (max-width: 450px)": {
                              fontSize: ".95rem",
                              margin: "-1rem .5rem .5rem",
                            },
                          }}
                        >
                          {t("business_help_develop_product")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className="business-para"
                          variant="h6"
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            "@media screen and (max-width: 450px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {t("business_store_development")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            "@media screen and (max-width: 450px)": {
                              fontSize: ".95rem",
                              margin: "-1rem .5rem .5rem",
                            },
                          }}
                        >
                          {t("business_helps_sell_more")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className="business-para"
                          variant="h6"
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            "@media screen and (max-width: 450px)": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {t("business_success_driven")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{
                            "@media screen and (max-width: 450px)": {
                              fontSize: ".95rem",
                              margin: "-1rem .5rem .5rem",
                            },
                          }}
                        >
                          {t("business_strive")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </LayoutComponent>
      </section>
      <section className="business">
        <LayoutComponent>
          <Typography
            variant="h2"
            className="business-header"
            mb={2}
            sx={{
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            {t("business_how_master_franchising")}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography className="business-para">
                  {t("business_the_candidates_capital")}
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    px: 10,
                    "@media screen and (max-width: 700px)": {
                      padding: 0,
                    },
                  }}
                  className="business-model-ul"
                >
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_the_candidates_current")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_number_of_stores")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_other_market")}
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <Typography className="business-para">
                  {t("business_candidates_must_have")}
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    px: 10,
                    "@media screen and (max-width: 700px)": {
                      padding: 0,
                    },
                  }}
                  className="business-model-ul"
                >
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_human_resources")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_merchandising")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_Operations")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_Logistics")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_store_development")}
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {" "}
                      <CircleIcon fontSize=".1rem" color="success" />
                    </ListItemIcon>
                    {t("business_marketing")}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </LayoutComponent>
      </section>
      <section className="business">
        <div className="invest-container">
          <img alt="invest" src={investImage}></img>
          <div className="invest-text business-header">
            {t("business_invest_in_success")}
          </div>
        </div>
      </section>
      <section className="business business-support">
        <LayoutComponent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <img
                  alt="model support"
                  src={modelValue7}
                  className="img-fluid"
                ></img>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2 className="business-header">
                  {t("business_strong_support_system")}
                </h2>
                <h2 className="business-h2">{t("business_reg_direct")}</h2>
                <p className="business-para">
                  {t("business_reg_direct_provides")}
                </p>
                <h2 className="business-h2">{t("business_new_market")}</h2>
                <p className="business-para">
                  {t("business_this_guide_allows")}
                </p>
                <h2 className="business-h2">{t("business_int_support")}</h2>
                <p className="business-para">
                  {t("business_as_direct_by_regdirect")}
                </p>
                <p>&nbsp;</p>
              </Grid>
            </Grid>
          </Box>
          <div className="blockGap"></div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <h2 className="business-header">
                  {t("business_extensive_support")}
                </h2>
                <h2 className="business-h2">
                  {t("business_practices_newsletters")}
                </h2>
                <p className="business-para">
                  {t("business_on_a_quarterly_basis")}
                </p>
                <h2 className="business-h2">
                  {t("business_global_regs_workshops")}
                </h2>
                <p className="business-para">
                  {t("business_workshops_on_relevant_topics")}&nbsp;
                </p>
                <h2 className="business-h2">{t("business_global_summits")}</h2>
                <p className="business-para">
                  {t("business_master_franchisee")}
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-lg-6 text-center section-image">
                  <img src={supportImage} alt="" className="img-fluid"></img>
                </div>
              </Grid>
            </Grid>
          </Box>
        </LayoutComponent>
      </section>
      <section className="business bg-red">
        <LayoutComponent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              className="grid-container"
              sx={{
                "@media(max-width:900px)": { flexDirection: "column-reverse" },
              }}
            >
              <Grid item xs={12} md={6}>
                <div className="col-lg-6 order-lg-1 text-center section-image">
                  <img
                    src={criticalQualification}
                    alt=""
                    className="img-fluid img-red"
                  ></img>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2 className="business-header">
                  {t("business_critical_qualifications")}
                </h2>
                <ul className="business-ul">
                  <li className="business-para">
                    {t("business_people_management")}
                  </li>
                  <li className="business-para">
                    {t("business_local_real_estate")}
                  </li>
                  <li className="business-para">
                    {t("business_long_term_growth")}
                  </li>
                  <li className="business-para">
                    {t("business_local_retail")}
                  </li>
                  <li className="business-para">
                    {t("business_government_relations")}
                  </li>
                  <li className="business-para">
                    {t("business_financial_strength")}
                  </li>
                  <li className="business-para">
                    {t("business_commitment_model")}
                  </li>
                  <li className="business-para">
                    {t("business_quality_focus")}
                  </li>
                  <li className="business-para">
                    {t("business_customer_obsessed")}
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </LayoutComponent>
      </section>
      <section className="business">
        <LayoutComponent>
          <Typography
            variant="h2"
            className="business-header"
            mb={2}
            style={{ fontSize: "40px", fontWeight: "600" }}
          >
            {t("business_value_to_customers")}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <div className="item-container">
                  <img
                    className="value-item-image1"
                    alt=""
                    src={modelValue1}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_fast_friendly")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="item-container">
                <div className="item-container">
                  <img
                    className="value-item-image"
                    alt=""
                    src={modelValue2}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_extended_hours")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="item-container">
                <div className="item-container">
                  <img
                    className="value-item-image"
                    alt=""
                    src={modelValue3}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_close_convenient")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="item-container">
                <div className="item-container">
                  <img
                    className="value-item-image"
                    alt=""
                    src={modelValue4}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_high_quality")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="item-container">
                <div className="item-container">
                  <img
                    className="value-item-image"
                    alt=""
                    src={modelValue5}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_consumer_focused")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="item-container">
                  <img
                    className="value-item-image"
                    alt=""
                    src={modelValue6}
                  ></img>
                  <div className="value-item-text business-para">
                    {t("business_competitive_pricing")}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </LayoutComponent>
      </section>
      <section className="business">
        <Typography
          variant="h2"
          textAlign="center"
          fontWeight={6}
          className="business-header"
          mb={10}
          sx={{ fontSize: "36px", fontWeight: "600" }}
        >
          {t("business_global_impact")}
        </Typography>
        <div className="video-container">
          <video controls autoPlay controlsList="nodownload" ref={videoRef}>
            <source src={globalImpact} type="video/mp4" />
          </video>
        </div>
      </section>
      <div className="next-button-container" style={{}}>
        <button className="next-button">
          <Link to="/int-countries" className="next-button-link">
            {t("business_next_countries")}
          </Link>
        </button>
      </div>
    </div>
  );
};
export default Business;
