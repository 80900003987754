import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navigation = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contact-us`);
  };

  return (
    <div className="navigation">
      <div className="navigation-items">
        <NavLink to="/about" className="nav-link buttons">
          {t("header_about")}
        </NavLink>
        <NavLink to="/careers" className="nav-link buttons">
          {t("header_careers")}
        </NavLink>
        <NavLink to="/intbrand" className="nav-link buttons">
          {t("international_franchise")}
        </NavLink>
        <NavLink to="/esg" className="nav-link buttons">
          ESG
        </NavLink>
        <a className="buttons">
          <button className="contactUsBtn" onClick={handleClick}>
            Supplier
          </button>
        </a>
        <a className="contact-button">
          <button className="contactUsBtn" onClick={handleClick}>
            {t("contact_us")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Navigation;
