import { React, useState, useContext, useEffect } from "react";
import "./TopNavigation.css";
import i18next from "i18next";
import cookies from "js-cookie";
import { AiOutlineGlobal } from "react-icons/ai";
import { RiUserAddLine } from "react-icons/ri";

import { useTranslation } from "react-i18next";
import { UserContext } from "../../../App";
import { languages } from "../../../Constants/LanguageList";
import { Link } from "react-router-dom";
import UserProfile from "../../Content/UserProfile";
import Notification from "../../Assets/PrivateBrandCatalog/Notification";
import { redirect_uri, login_domain, client_id, isLoginEnabled } from "../../../config/environment";
import { checkFeatureAccess } from "../../../Utils/CommonUtils";

const TopNavigation = (props) => {
  const [dropDown, setDropDown] = useState(false);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(UserContext);

  // const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
  // const client_id = process.env.REACT_APP_SICI;
  // const login_domain = process.env.REACT_APP_LOGIN_DOMAIN;
  const login_redirect = `https://${login_domain}/login?client_id=${client_id}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://${redirect_uri}/assets`;

  const checkAccess = localStorage.getItem("access_token");
  const checkExpiry = localStorage.getItem("expiry_time");

  // const isLoginEnabled = process.env.REACT_APP_LOGIN_ENABLED;
  let userPermission = localStorage.getItem('userpermission');

  if (checkAccess && checkExpiry) {
    dispatch({ type: "USER", payload: true });
  }
  document.addEventListener("click", function (e) {
    if (
      document.getElementById("dropDown") &&
      !document.getElementById("dropDown").contains(e.target)
    ) {
      setDropDown(false);
    }
  });

  let renderTopNav;

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const handleUserDetailUpdate = () => {
    props.handleUserDetailUpdate();
  };

  const links =[
    {url: "/brandedMerchandise", text: "Branded Merchandise", feature: "Branded Merchandise",requiresAccessCheck:false},
    {url: "/privateBrandCatalog", text: "Private Brand Catalog", feature: "Private Brand Catalog", access: "view", requiresAccessCheck: true },
    {url: "/assets/", text: "Digital Assets", feature: "Digital Assets", requiresAccessCheck: false },
    {url: "/userManagement", text: "User Management", feature: "User Management", requiresAccessCheck: true }
  ]

  if (state) {
    renderTopNav = (
      <>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
        />
        <div className="top-navi">
          <div className="top-navi-items">
            {links.map((link, index) => {
              const displayLink  = !link.requiresAccessCheck || checkFeatureAccess(link.feature, link.access);
              if (displayLink) {
                return <Link key={index} to={link.url} className="links hideForMV">{link.text}</Link>;
              }
            })}
            {/* <Notification /> */}
            <UserProfile
              state={state}
              handleUserDetailUpdate={handleUserDetailUpdate}
            />
            {/* <ul className="language-container drop-down" id="dropDown">
              <AiOutlineGlobal
                className="language-icon"
                onClick={() => setDropDown(!dropDown)}
              />
              <button
                className="language-button"
                onClick={() => setDropDown(!dropDown)}
              />

              {dropDown === true && (
                <div className="card">
                  <ul>
                    {languages.map(({ code, name, countryCode }) => (
                      <li key={countryCode} className="language-items">
                        <button
                          className="dropdown-buttons"
                          onClick={() => {
                            i18next.changeLanguage(code);
                            setDropDown(!dropDown);
                          }}
                        >
                          <span className={`fi fi-${countryCode}`}></span>
                          {name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </ul> */}
          </div>
        </div>
      </>
    );
  } else {
    renderTopNav = (
      <>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
        />
        <div className="top-navi">
          <div className="top-navi-items">
            <a href={login_redirect} className="links hideForMV">
              {isLoginEnabled === "true" && (
                <div className="login-button-container">
                  <RiUserAddLine
                    className="login-icon"
                    title="Sign in to your account"
                  />
                </div>
              )}
            </a>
            {/* <ul className="language-container drop-down" id="dropDown">
              <AiOutlineGlobal
                className="language-icon"
                onClick={() => setDropDown(!dropDown)}
              />
              <button
                className="language-button"
                onClick={() => setDropDown(!dropDown)}
              />

              {dropDown && (
                <div className="card">
                  <ul>
                    {languages.map(({ code, name, countryCode }) => (
                      <li key={countryCode} className="language-items">
                        <button
                          className="dropdown-buttons"
                          onClick={() => {
                            i18next.changeLanguage(code);
                            setDropDown(!dropDown);
                          }}
                        >
                          <span className={`fi fi-${countryCode}`}></span>
                          {name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </ul> */}
          </div>
        </div>
      </>
    );
  }

  return <>{renderTopNav}</>;
};

export default TopNavigation;
