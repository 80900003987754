import { Box, Typography } from "@mui/material";
import "../BrandedMerchandise.scss";
import NoImage from '../../PrivateBrandCatalog/assets/NoImage.png';

const LinearListItem = ({ product }) => {
  return (
    <Box id={product.itemMasterID} className="product-container">
      <Box className="product-details">
        <img
          src={product.imageUrl.length > 0 ? product.imageUrl[0] : NoImage}
          className="table-item-image"
        />
        <Typography variant="body1" className="product-name">
          {product.shortDescription}
        </Typography>
      </Box>
      <Box className="pricing-details">
        <Typography variant="body2" className="price">
          Price USD: {product.brandedMerchandisePrice}
        </Typography>
        <Typography variant="body2" className="srp">
          SRP USD: {product.minSrp}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearListItem;
